/**
 * Version target on server side
 * @type { string }
 */
const vServeur = '.4.2';

/**
 * Domain name for vod-dev
 * @type {string}
 */
export const apiDomain = 'https://inlive.aitakes.com/';

/**
 * Prefix for API calls on vod-dev
 * @type {string}
 */
const apiRoute = 'api/';

/**
 * Complete API URL for vod-dev
 * @type {string}
 */
const apiUrl = apiDomain  + apiRoute;

/**
 * Domain name for admin-vod
 * @type {string}
 */
const apiAdminDomain = 'https://admin-vod.zone300.com/';

/**
 * Prefix for API calls on admin-vod
 * @type {string}
 */
const apiAdminRoute = 'api/';

/**
 * Returns the complete API URL for admin-vod
 * @type {string}
 */
export function getApiAdminUrl() {
  return apiAdminDomain + apiAdminRoute;
}

/**
 * Returns the URL for login
 * @param login
 * @param password
 * @returns {string}
 * @constructor
 */
export function getLoginUrl( login: string, password: string ) {
  return apiUrl + 'appli_inlive/login.php';
}

export function getPurchasesUrl( noCli: string ) {
  return apiUrl + 'appli_inlive/get_Purchases.php?NoCli=' + noCli;
}

// /**
//  * Returns the URL to retrieve the user customization data
//  * @param NoCli
//  * @returns {string}
//  * @constructor
//  */
// export function getUserCustomization( NoCli ) {
//   return apiUrl + 'appli_inlive/get_Personnalisation_v2.php?NoCli=' + NoCli;
// }

export function getCatalog( noCli ) {
  return apiUrl + 'appli_inlive/get_Media2.php?NoCli=' + noCli;
}

// /**
//  * Returns the URL to retrieve the catalog of a category
//  * @param category
//  * @returns {string}
//  * @constructor
//  */
// export function getCategoryCatalog( category ) {
//   return apiUrl + 'getAccueil_appli.php?action=' + category;
// }

/**
 * Returns the URL to retrieve the carousel content of a category
 * @returns {string}
 * @constructor
 */
export function getCategoryCarousel() {
  return apiUrl + 'appli_inlive/get_Carousel2.php';
}

/**
 * Returns the URL to retrieve the data of a film
 * @param filmId
 * @returns {string}
 * @constructor
 */
export function getMovie( filmId: string, noCli: string ) {
  return apiUrl + 'appli_inlive/get_MediaByTransaction.php?transac=' + filmId + '&NoCli=' + noCli;
}

/**
 * Returns the URL to retrieve the result of a search
 * @param search
 * @returns {string}
 * @constructor
 */
export function getSearch( search ) {
  return apiUrl + 'getSearch_v3b.php?action=search&q=' + search;
}

/**
 * URL for landing page carousel's data
 * @type {string}
 */
export function apiLandingCarouselImage() {
  return getApiAdminUrl() + 'appli/getAccueil.php';
}
