import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CssClassUtils from '../utils/CssClassUtils';


/**
 * This components is a wrapper.
 *
 
 **/
class Wrapper extends Component {
  /**
   * The sticky bottom state.
   **/
  static STICKY_BOTTOM = 'sticky-bottom';
  
  /**
   * The sticky bottom state.
   **/
  static STICKY_BOTTOM_MB = 'sticky-bottom-mb';

  /**
   * The none padding state.
   **/
  static PADDING_NONE = 'padding-none';

  /**
   * The none padding state.
   **/
  static POSITION_CENTERED = 'centered';

  /**
   * The size half state.
   **/
  static SIZE_HALF = 'size-half';

  /**
   * The size two third state.
   **/
  static SIZE_TWO_THIRD = 'size-two-third';

  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'wrapper';

  /**
   * The css class for overlay
   **/
  static OVERLAY = 'overlay';

  /**
   * The css class for overlay with gradient from left
   **/
  static OVERLAY_GRADIENT_LEFT = 'overlay-gl';

  /**
   * The css class for overlay with gradient from left
   **/
  static OVERLAY_GRADIENT_BCKGND = 'overlay-gbckgnd';

  /**
   * The css class for overlay with gradient from bottom
   **/
  static OVERLAY_GRADIENT_BOTTOM = 'overlay-gbottom';

  /**
   * The sticky bottom css class name.
   **/
  static CSS_CLASS_STICKY_BOTTOM = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.STICKY_BOTTOM;

  /**
   * The sticky bottom with margin bottom css class name.
   **/
  static CSS_CLASS_STICKY_BOTTOM_MB = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.STICKY_BOTTOM_MB;

  /**
   * The padding none css class name.
   **/
  static CSS_CLASS_PADDING_NONE = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.PADDING_NONE;

  /**
   * The position centered css class name.
   **/
  static CSS_CLASS_POSITION_CENTERED = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.POSITION_CENTERED;

  /**
   * The size half css class name.
   **/
  static CSS_CLASS_SIZE_HALF = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.SIZE_HALF;
  
  /**
   * The size two third css class name.
   **/
  static CSS_CLASS_SIZE_TWO_THIRD = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.SIZE_TWO_THIRD;

  /**
   * The overlay css class name.
   **/
  static CSS_CLASS_OVERLAY = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.OVERLAY;

  /**
   * The overlay with gradient from left css class name.
   **/
  static CSS_CLASS_OVERLAY_GRADIENT_LEFT = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.OVERLAY_GRADIENT_LEFT;

  /**
   * The overlay with gradient from back css class name.
   **/
  static CSS_CLASS_OVERLAY_GRADIENT_BCKGND = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.OVERLAY_GRADIENT_BCKGND;
  
  /**
   * The overlay with gradient from back css class name.
   **/
  static CSS_CLASS_OVERLAY_GRADIENT_BOTTOM = Wrapper.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Wrapper.OVERLAY_GRADIENT_BOTTOM;

  /**
   * Return the css classes depending on the props.
   *-
   *
   * @return string The css classes.
   *
   
   * @param sticky
   * @param padding
   * @param position
   * @param size
   * @param overlay
   **/
  getCssClasses( sticky: string, padding: string, position: string, size: string, overlay: string ) {
    let classes = Wrapper.CSS_CLASS_BASE;

    switch ( sticky ) {
      case Wrapper.STICKY_BOTTOM:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_STICKY_BOTTOM;
        break;
      case Wrapper.STICKY_BOTTOM_MB:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_STICKY_BOTTOM_MB;
        break;
    }


    if ( padding === Wrapper.PADDING_NONE ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_PADDING_NONE;
    }

    if ( position === Wrapper.POSITION_CENTERED ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_POSITION_CENTERED;
    }

    switch( size ) {
      case Wrapper.SIZE_HALF:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_SIZE_HALF;
        break;
      case Wrapper.SIZE_TWO_THIRD:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_SIZE_TWO_THIRD;
        break;
    }

    switch ( overlay ) {
      case Wrapper.OVERLAY_GRADIENT_LEFT:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY_GRADIENT_LEFT;
        break;
      case Wrapper.OVERLAY_GRADIENT_BCKGND:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY_GRADIENT_BCKGND;
        break;
      case Wrapper.OVERLAY_GRADIENT_BOTTOM:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY_GRADIENT_BOTTOM;
        break;
      case Wrapper.OVERLAY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
        break;
      default:
        break
    }

    return classes;
  }

  /**
   * Render the wrapper.
   *
   
   */
  render() {
    return (
      <div className={
        this.getCssClasses(
          this.props.sticky,
          this.props.padding,
          this.props.position,
          this.props.size,
          this.props.overlay,
        )
      }>
        { this.props.children }
      </div>
    )
  }
}

/**
 * @props string sticky The type of stick (bottom, top, etc).
 * @props string padding The padding style.
 * @props string position The centered position.
 * @props string size The width of the wrapper.
 **/
Wrapper.propTypes = {
  sticky: PropTypes.oneOf( [ Wrapper.STICKY_BOTTOM, Wrapper.STICKY_BOTTOM_MB ] ),
  padding: PropTypes.oneOf( [ Wrapper.PADDING_NONE ] ),
  position: PropTypes.oneOf( [ Wrapper.POSITION_CENTERED ] ),
  size: PropTypes.oneOf( [ Wrapper.SIZE_HALF, Wrapper.SIZE_TWO_THIRD ] ),
  overlay: PropTypes.oneOf( [
    Wrapper.OVERLAY,
    Wrapper.OVERLAY_GRADIENT_LEFT,
    Wrapper.OVERLAY_GRADIENT_BCKGND,
    Wrapper.OVERLAY_GRADIENT_BOTTOM
  ] ),
};

export default Wrapper;