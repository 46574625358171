import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StringUtils from '../utils/StringUtils';
import CssClassUtils from '../utils/CssClassUtils';

/**
 * This components is Title.
 *
 
 **/
class Title extends Component {
  /**
   * The level 1 (h1).
   **/
  static LEVEL_1 = 1;

  /**
   * The level 2 (h2).
   **/
  static LEVEL_2 = 2;

  /**
   * The level 3 (h3).
   **/
  static LEVEL_3 = 3;

  /**
   * The level 4 (h4).
   **/
  static LEVEL_4 = 4;

  /**
   * The level 5 (h5).
   **/
  static LEVEL_5 = 5;

  /**
   * The level 6 (h6).
   **/
  static LEVEL_6 = 6;

  /**
   * The primary color
   **/
  static COLOR_PRIMARY = 'primary';

  /**
   * The secondary color
   **/
  static COLOR_SECONDARY = 'secondary';

  /**
   * The align center
   * @TODO set center in parent block when possible
   **/
  static ALIGN_CENTER = 'center';

  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'title';

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_1 = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.LEVEL_1;

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_2 = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.LEVEL_2;

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_3 = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.LEVEL_3;

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_4 = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.LEVEL_4;

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_5 = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.LEVEL_5;

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_6 = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.LEVEL_6;

  /**
   * The primary color css classname
   **/
  static CSS_CLASS_COLOR_PRIMARY = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.COLOR_PRIMARY;

  /**
   * The secondary color css classname
   **/
  static CSS_CLASS_COLOR_SECONDARY = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.COLOR_SECONDARY;

  /**
   * The center alignment css classname
   **/
  static CSS_CLASS_ALIGN_CENTER = Title.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Title.ALIGN_CENTER;

  /**
   * Return the css classes depending on the props.
   *
   * @return string The css classes.
   *
   
   **/
  getCssClasses() {
    let classes = ( this.props.className ) ?
    this.props.className + CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_BASE :
    Title.CSS_CLASS_BASE;

    switch ( this.props.level ) {
      case Title.LEVEL_1:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_1;
        break;
      case Title.LEVEL_2:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_2;
        break;
      case Title.LEVEL_3:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_3;
        break;
      case Title.LEVEL_4:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_4;
        break;
      case Title.LEVEL_5:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_5;
        break;
      case Title.LEVEL_6:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_6;
        break;
      default:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_STATE_1;
        break;
    }

    switch ( this.props.color ) {
      case Title.COLOR_SECONDARY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_COLOR_SECONDARY;
        break;
      default:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_COLOR_PRIMARY;
    }

    if ( this.props.align === Title.ALIGN_CENTER ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Title.CSS_CLASS_ALIGN_CENTER;
    }

    return classes;
  }

  /**
   * Format the value of the title.
   *
   * @param value
   *
   * @return object The value of the title.
   *
   
   */
  getValueFormated( value: string, capitalize: boolean = false ) {
    return capitalize ? StringUtils.toCapitalize( value ) : value;
  }

  /**
  * Get the good title rendering depending on the level.
  *
  * @param int level The level of the title
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  getHtmlTag(level: int, content)
  {
    let tag;
  
    switch(level) {
        case Title.LEVEL_1:
          tag = this.renderTitle1(content)
          break;
        case Title.LEVEL_2:
          tag = this.renderTitle2(content)
          break;
        case Title.LEVEL_3:
          tag = this.renderTitle3(content)
        break;
        case Title.LEVEL_4:
          tag = this.renderTitle4(content)
          break;
        case Title.LEVEL_5:
          tag = this.renderTitle5(content)
          break;
        case Title.LEVEL_6:
          tag = this.renderTitle6(content)
          break;
        default:
          tag = this.renderTitle1(content)
          break;
  
  
      }

    return tag;
  }

  /**
  * Render the title level 1
  *
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  renderTitle1(content)
  {
    return (<h1 className={ this.getCssClasses()}>{ content }</h1>);
  }

  /**
  * Render the title level 2
  *
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  renderTitle2(content)
  {
    return (<h2 className={ this.getCssClasses()}>{ content }</h2>);
  }

  /**
  * Render the title level 3
  *
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  renderTitle3(content)
  {
    return (<h3 className={ this.getCssClasses()}>{ content }</h3>);
  }

  /**
  * Render the title level 4
  *
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  renderTitle4(content)
  {
    return (<h4 className={ this.getCssClasses()}>{ content }</h4>);
  }

  /**
  * Render the title level 5
  *
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  renderTitle5(content)
  {
    return (<h5 className={ this.getCssClasses()}>{ content }</h5>);
  }

  /**
  * Render the title level 6
  *
  * @param object content The content of the title.
  *
  * @return rendered html The title to be rendered.
  *
  
  */
  renderTitle6(content)
  {
    return (<h6 className={ this.getCssClasses()}>{ content }</h6>);
  }

  /**
   * Render the Title.
   *
   * @Todo create a h1, h2, h3, h4, h5, h6 components to return the good html tag depending on the level props.
   *
   
   */
  render() {
    const content = StringUtils.isString( this.props.children ) ?
      this.getValueFormated( this.props.children, this.props.capitalize ) :
      this.props.children;

    return (
      <React.Fragment key="component-title">
        { this.getHtmlTag(this.props.level, content) }
      </React.Fragment>
    )
  }
}

/**
 * @props object value The value.
 * @props integer level The title level.
 **/
Title.propTypes = {
  level: PropTypes.oneOf( [
    Title.LEVEL_1,
    Title.LEVEL_2,
    Title.LEVEL_3,
    Title.LEVEL_4,
    Title.LEVEL_5,
    Title.LEVEL_6,
  ] ),
  color: PropTypes.oneOf( [
    Title.COLOR_PRIMARY,
    Title.COLOR_SECONDARY,
  ] ),
  align: PropTypes.oneOf( [
    Title.ALIGN_CENTER,
  ] ),
  capitalize: PropTypes.bool
};

Title.defaultProps = {
  color: Title.COLOR_PRIMARY,
  capitalize: true,
};

export default Title;