import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Focusable } from 'react-key-navigation';
import CssClassUtils from '../utils/CssClassUtils';

import Label from '../components/Label';


class Input extends Component {
  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'form__input';

  /**
   * The medium size.
   **/
  static SIZE_MEDIUM = 'size-medium';

  /**
   * The big size.
   **/
  static SIZE_BIG = 'big';

  /**
   * The focused state.
   */
  static STATE_FOCUS = 'focus';

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_FOCUS = Input.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Input.STATE_FOCUS;

  /**
   * The medium css class name.
   **/
  static CSS_CLASS_SIZE_MEDIUM = Input.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_BLOCK_SEPARATOR + Input.SIZE_MEDIUM;

  /**
   * The transparent style class name.
   */
  static CSS_CLASS_SIZE_BIG = Input.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_BLOCK_SEPARATOR + Input.SIZE_BIG;

  /**
   * Constructor of the Input class
   * Set the active state to false.
   * @param props
   */
  constructor( props ) {
    super( props );

    this.state = {
      active: false,
      inputActive: false,
    }
    this.inputFocusable = React.createRef();
  }

  /**
   * Return the css classes depending on the params.
   * @return string The css classes.
   **/
  getCssClasses( size: string, isActive: bool ) {
    let classes = Input.CSS_CLASS_BASE;

    if ( size === Input.SIZE_BIG ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Input.CSS_CLASS_SIZE_BIG;
    } else {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Input.CSS_CLASS_SIZE_MEDIUM;
    }

    if ( isActive ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Input.CSS_CLASS_STATE_FOCUS;
    }

    return classes;
  }

  /**
   * Set the active state to false when blur
   */
  onBlur() {
    this.setState( { active: false } );
    this.input.blur();
    if ( this.props.parent ) this.props.parent.setInputActive( false );
  }

  /**
   * Set the active state to true when focus
   */
  onFocus() {
    this.setState( { active: true } );
    if ( this.props.parent )this.props.parent.setInputActive( true );
  }

  onFocusInput() {
    if ( this.props.navigation ) this.props.navigation.forceFocus( this.inputFocusable.focusableId );
  }

  /**
   * Set the input active and force the focus.
   *
   */
  onClick() {
    this.props.navigation.forceFocus( this.inputFocusable.current.focusableId );
  }

  componentDidMount() {
    console.log('inp', this.input);
    this.input.addEventListener('keydown', e => {
      if ( [ 37, 39 ].includes( e.keyCode ) ) {
        console.log(this.input.selectionStart)
      }
    });
    
    // this.input.addEventListener('keypress', e => {
    //   console.log('keypress', e.keyCode);
    // });
    // this.input.addEventListener('keydown', e => {
    //   console.log('keyup', e.keyCode);
    // });
  }

  setCursorPosition( pos: number ) {
    //After IE8
    if ( this.input.setSelectionRange ) {
      this.input.focus();
      this.input.setSelectionRange( pos, pos );
    }
    //IE8 and below
    else if (this.input.createTextRange) {
      var range = this.input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  /**
   * Render the html input.
   */
  render() {
    return (
      <Focusable
        ref={ this.inputFocusable }
        onFocus={ () => this.onFocus() }
        onBlur={ () => this.onBlur() }
        onEnterDown={ ( e, n ) => { this.state.inputActive ? this.input.blur() : this.input.focus() } }
        navDefault={ this.props.autoFocus }
      >
        <div className="form__group">
          {
            this.props.withLabel &&
            <Label
              name={ this.props.name }
              value={ this.props.label }
              isActive={ this.state.active }
            >
            </Label>
          }
          <input
            ref={ ( input ) => { this.input = input } }
            id={ 'input-' + this.props.name }
            className={ this.getCssClasses( this.props.size, this.state.active ) }

            autoComplete={ this.props.autoComplete }
            autoFocus={ this.props.autoFocus }
            name={ this.props.name }
            tabIndex={ this.props.tabIndex }
            type={ this.props.type }
            value={ this.props.value }

            onBlur={ () => {
              this.setState( { inputActive: false } )
              if ( this.props.parent ) this.props.parent.setInputActive( false );
            } }
            onFocus={ () => {
              this.setState( { inputActive: true,  active: true } );
              if ( this.props.navigation && this.inputFocusable.current ) this.props.navigation.forceFocus( this.inputFocusable.current.focusableId );
            } }
            onChange={ this.props.onChange }
            onClick={ () => this.onClick() }
          />
        </div>
      </Focusable>
    )
  }
}

/**
 * @props string type The type of the input.
 * @props string label The label value of the input.

 * @props Object navigation The navigation object to permit the forceFocus().

 * @props string name The name of the input.
 * @props string size The size of the input. Giant, big or medium, medium by default.
 **/
Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  focusOnLoad: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.oneOf( [ Input.SIZE_MEDIUM ] ),
  withLabel: PropTypes.bool,
  value: PropTypes.string,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
};

/**
 * @type {{autoComplete: boolean, focusOnLoad: boolean, style: (string|*), block: (string|*), withLabel: boolean, value: (string|*)}}
 */
Input.defaultProps = {
  focusOnLoad: false,
  value: Input.VALUE_DEFAULT,
  style: Input.STYLE_TRANSPARENT,
  block: Input.BLOCK_DEFAULT,
  withLabel: true,
  autoComplete: false,
  autoFocus: false,
  tabIndex: null,
};

export default Input;