import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Navigation, { Focusable, VerticalList, HorizontalList } from 'react-key-navigation';
import { Spinner } from 'react-activity';

import Carousel from '../components/Carousel';
import Sidebar from '../components/SideBar';
import Category from '../components/Category';

import { getCategoryCarousel, getCatalog } from '../api/ApiManager';
import { goBack, goTo, isReturnEvent, PATH_HOME, PATH_LOGIN } from "../utils/NavigationUtils";
import UserConnection from '../api/UserConnection';


/**
 * Page containing carousel, and list of films
 */
class Catalog extends Component {
  /**
   * List of films categories
   * @type {[]}
   */
  catalogList = [];

  /**
   * List of products
   * @type {[]}
   */
  products = [];

  /**
   * List of images for the carousel
   * @type {[]}
   */
  carImg = [];

  /**
   * Data corresponding to the actual user
   * @type {null | object}
   */
  userData = null;

  /**
   * Constructor of the Catalog class
   * @param props
   */
  constructor( props ) {
    super( props );

    this.state = {
      isLogged: false,
      category: sessionStorage.getItem( 'menuKey' ) || 'home',
      pageLoaded: false,
      listLoaded: false,
      carouselLoaded: false,
      activeList: null,
      carouselActive: true,
    };

    this.userData = this.getUserData();
    this.isUserLogged( this.userData );
    this.getListsContent();
    this.getCarouselContent();

    this.carouselMouseEnter = this.carouselMouseEnter.bind( this );
    this.redirectTo = this.redirectTo.bind( this );
    this.setListActive = this.setListActive.bind( this );
    this.keyDownListener = this.keyDownListener.bind( this );
  }

  /**
   * Redirect to the given url
   * @TODO delete & use goTo navigationUtils
   * @param url
   */
  redirectTo( url: string ) {
    // this.props.history.push( url );
    goTo( this.props.history, url );
  }

  /**
   * Update state isLogged if user connected or redirect to login page
   * @param userData
   */
  isUserLogged( userData ) {
    if ( userData ) {
      this.setState( { isLogged: true } );
    } else {
      this.redirectTo( PATH_LOGIN );
      this.props.app.setState( { isLogged: false } );
    }
  }

  /**
   * Get user's data from localstorage
   * @returns {object}
   */
  getUserData() {
    return JSON.parse( localStorage.getItem( 'LOGIN_TOKEN' ) );
  }

  /**
   * Get list of categories and films
   */
  getListsContent() {
    axios.get( getCatalog( localStorage.getItem( UserConnection.ITEM_NO_CLIENT ) ) )
      .then( res => {
        this.products = res.data;
        this.setState( { listLoaded: true } );

        if ( this.state.carouselLoaded ) {
          this.navigation.forceFocus( this.carouselFocusable.focusableId );
        }
      } );
  }

  /**
   * Get data for the carousel
   */
  getCarouselContent() {
    axios.get( getCategoryCarousel( this.state.category ) )
      .then( res => {
        if ( res.status ) {
          this.carImg = res.data.data;
          this.setState( { carouselLoaded: true } );
          if ( this.state.listLoaded ) {
            this.navigation.forceFocus( this.carouselFocusable.focusableId );
          }
        }
      } );
  }

  /**
   * Set carouselActive state and focus/blur actual visible carousel element
   * @param active
   */
  setCarouselActive( active: boolean ) {
    this.setState( { carouselActive: active } );

    if ( this.carousel && this.carousel.carousel ) {
      active ? this.carousel.carousel.carouselWrapperRef.focus() :
        this.carousel.carousel.carouselWrapperRef.blur()
    }
  }

  /**
   * Set activeList state to given index or null
   * @param index
   */
  setListActive( index: number = null ) {
    this.setState( { activeList: index } );
  }

  /**
   * Set focus on carousel on mouse enter event
   */
  carouselMouseEnter() {
    this.navigation.forceFocus( this.carouselFocusable.focusableId );
  }

  /**
   * Opdn sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) && this.sidebar && this.navigation && !this.sidebar.state.active ) {
      this.sidebar.setActive( true );
      this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
      goTo( this.props.history, PATH_HOME, true );
    }
  }

  /**
   * Update state pageLoaded
   */
  componentDidMount() {
    this.setState( { pageLoaded: true } );
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  shouldComponentUpdate( nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any ): boolean {
    if ( this.props.match.params.action === 'quit' && !nextProps.match.params.action ){
      return false;
    }
    return true;
  }

  /**
   * On page change 'monZ3 / chasse / peche' get data corresponding
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate( prevProps, prevState, snapshot ) {
    if ( prevState.category !== this.state.category ) {
      sessionStorage.setItem( 'menuKey', this.state.category );

      this.setState( { carouselLoaded: false, listLoaded: false } );
      this.getListsContent();
      this.getCarouselContent();
    }

    if ( this.props.match.params.action === 'quit' ) {
      this.keyDownListener( new KeyboardEvent( 'keydown', { keyCode: 27 } ) );
    }
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Render method for the Catalog class
   * @returns {JSX.Element}
   */
  render() {
    let { category, listLoaded, carouselLoaded, carouselActive } = this.state;

    return (
      <Navigation ref={ ( el ) => { this.navigation = el } }>
        <div
          ref={ ( el ) => this.catalog = el }
          className="catalog"
        >
          {
            carouselLoaded && listLoaded ? 
              <HorizontalList>
                <Sidebar
                  ref={ ( el ) => this.sidebar = el }
                  category="home"
                  history={ this.props.history }
                  navigation={ this.navigation }
                  parent={ this }
                />
                <VerticalList ref={ ( el ) => this.mainFocus = el } retainLastFocus><Fragment>
                  <Focusable
                    className="carousel--focusable"
                    ref={ ( el ) => this.carouselFocusable = el }
                    onMouseEnter={ this.carouselMouseEnter }
                    onFocus={ () => {
                      this.setCarouselActive( true );
                      this.setListActive();
                    } }
                    onBlur={ () => this.setCarouselActive( false ) }
                    navDefault={ true }
                  >
                    <Carousel
                      parent={ this }
                      infiniteLoop={ true }
                      showThumbs={ false }
                      useKeyboardArrows={ true }
                      autoPlay={ true }
                      showStatus={ false }
                      ref={ ( el ) => this.carousel = el }
                      images={ this.carImg }
                      className={ 'carousel' + (carouselActive ? ' carousel--active' : '') }
                      redirectTo={ this.redirectTo }
                      goBack={ () => goBack( this.props.history ) }
                    />
                  </Focusable>
                  {
                    // this.catalogList.map( ( cat, i ) => {
                    //   return this.products[ 'idx' + cat.id ].length > 0 &&
                        <Category
                          categoryIndex={ 0 }
                          name={ "M. Pokora" }
                          items={ this.products }
                          parent={ this }
                          setListActive={ this.setListActive }
                          redirectTo={ this.redirectTo }
                          goBack={ () => goBack( this.props.history ) }
                          navigation={ this.navigation }
                          viewport={ this.catalog }
                        />
                    // } )
                  }
                </Fragment>
                </VerticalList>
              </HorizontalList> :
              <div className="catalog__loader">
                <Spinner color="#fff" size={ 52 } speed={ 0.7 } animating={ true }/>
              </div>
          }
        </div>
      </Navigation>
    );
  }
}

export default Catalog;
