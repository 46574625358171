import React, { Component } from 'react';

class MyPlayer extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false,
      film: 0,
      synopsis: '',
      keyCode: ''
    };

    this.vimeoID = 0;
    this.userData = this.getUserData();
  }

  componentDidMount() {
  }

  loaded( obj, el ) {
    this.player.player.element.focus();
    this.player.player.play();
    this.player.autoplay( true );
  }

  getUserData() {
    return JSON.parse( localStorage.getItem( 'LOGIN_TOKEN' ) );
  }

  render() {
    const filmLink = localStorage.getItem( 'FilmHls' );
    const filmId = localStorage.getItem( 'FilmId' );

    localStorage.removeItem( "Film" );
    localStorage.removeItem( "FilmVideo" );
    localStorage.removeItem( "FilmHls" );
    window.GoVideo( filmLink, filmId, this.userData.NoCli );

    return (<></>);
  }
}

export default MyPlayer
