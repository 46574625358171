import React, { Component } from 'react';
import axios from 'axios';
import { getLoginUrl } from './ApiManager';

/**
 * This class permit the user connection thru the api.
 **/
class UserConnection extends Component {
  /**
   * The LOGIN_TOKEN key name for the local storage.
   */
  static ITEM_LOGIN_TOKEN = 'LOGIN_TOKEN';

  /**
   * The IS_GUEST key name for the local storage.
   */
  static ITEM_IS_GUEST = 'IS_GUEST';

  /**
   * The NO_CLIENT key name for the local storage.
   */
  static ITEM_NO_CLIENT = 'NO_CLIENT';

  /**
   * Connect a user based on his email and his password.
   *
   * @param email string The email of the user.
   * @param password string The password of the user.
   *
   * @return bool If the user is connected or no.
   **/
  static async connect( email: string, password: string ) {
    let result = false;

    const params = new URLSearchParams();
    params.append( 'email', email.trim() );
    params.append( 'password', password.trim() );

    const config = {
      headers: { 'Content-type': 'application/x-www-form-urlencoded' }
    }

    await axios
      .post( getLoginUrl(), params, config ).then(
        res => {
          if ( res.data.id ) {
            localStorage.setItem( UserConnection.ITEM_LOGIN_TOKEN, JSON.stringify( res.data ) );
            localStorage.setItem( UserConnection.ITEM_IS_GUEST, res.data.is_guest );
            localStorage.setItem( UserConnection.ITEM_NO_CLIENT, res.data.NoCli );

            result = true;
          }
        }
      );

    return result;
  }
}

export default UserConnection;