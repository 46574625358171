import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-activity';

/**
* This components is the loader display.
*

**/
class Loader extends Component {
  /**
  * The size default value.
  **/
  static SIZE_DEFAULT = 52;

  /**
  * The speed default value.
  **/
  static SPEED_DEFAULT = 0.7;

  /**
  * The animation default value.
  **/
  static ANIMATION_DEFAULT = true;

  /**
   * Default color for spinner
   */
  static COLOR_DEFAULT = '#fff'

  /**
  * Render the loader.
  *
  
  */
  render() {
    return (
      <div className="loader__wrapper">
        <Spinner 
          size={ this.props.size } 
          speed={ this.props.speed } 
          animating={ this.props.animation }
          color={ this.props.color }
        />
      </div>
    );
  }
}

/**
* @props string url The url of the button. Empty for a goBack.
* @props string size The size of the button. Big or medium, medium by default.
* @props string value The value of the button. 
**/
Loader.propTypes = {
  size: PropTypes.number,
  speed: PropTypes.number,
  animation: PropTypes.bool,
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: Loader.SIZE_DEFAULT,
  speed: Loader.SPEED_DEFAULT,
  animation: Loader.ANIMATION_DEFAULT,
  color: Loader.COLOR_DEFAULT,
};

export default Loader;