import React, { Component } from 'react';
import { VerticalList } from 'react-key-navigation';
import MenuItem from './MenuItem';
import Icon from "./Icon";
import { goTo, PATH_EXIT, PATH_HOME, PATH_LOGOUT, PATH_PURCHASE } from "../utils/NavigationUtils";


class Menu extends Component {
  /**
   * Array containing data for the sidebar items
   * @type {[{ref: string, icon: string, keyId: string, text: string, url: string}]}
   */
  static ITEMS = [
    {
      text: 'Accueil',
      ref: 'home',
      keyId: 'home',
      icon: 'home',
      url: PATH_HOME,
      replaceUrl: true,
      separator: false
    },
    {
      text: 'Mes achats',
      ref: 'purchase',
      keyId: 'purchase',
      icon: 'purchase',
      url: PATH_PURCHASE,
      replaceUrl: true,
      separator: true
    },
    {
      text: 'Déconnexion',
      ref: 'disconnect',
      keyId: 'disconnect',
      icon: 'logout',
      url: PATH_LOGOUT,
      replaceUrl: true,
      separator: false
    },
    {
      text: 'Quitter',
      ref: 'quit',
      keyId: 'quit',
      icon: 'quit',
      url: PATH_EXIT,
      replaceUrl: true,
      separator: false,
      minVersion: '210511' //min version for display in Android and Fire TVs
    }
  ];

  /**
   * Constructor method of the Menu component
   * @param props
   */
  constructor( props ) {
    super( props );

    this.onGo = this.onGo.bind( this );
  }

  /**
   * Use only state to define Categories state category
   * @param item
   */
  onGo( item ) {
    if ( window.location.pathname === item.url && item.url === PATH_HOME ) {
      this.props.page.setState( { category: item.keyId } );
    }
    goTo( this.props.history, item.url, item.replaceUrl );
    this.props.closeSidebar();
  }

  render() {
    return (
      <ul className="menu">
        <VerticalList
          className="menu__open"
          ref={ ( el ) => this.menuVList = el }
          onFocus={ () => this.props.openSidebar() }
          onBlur={ () => this.props.closeSidebar() }
        >
          {
            Menu.ITEMS.map( ( item ) => {
              if ( !item.minVersion || !window.getAndroidCapsuleVersion() || window.getAndroidCapsuleVersion() >= item.minVersion ) {
                  return <MenuItem
                    ref={ ( el ) => this[item.ref] = el }
                    goTo={ () => this.onGo( item ) }
                    keyId={ item.keyId }
                    icon={ item.icon }
                    navigation={ this.props.navigation }
                    parentSidebar={ this }
                    selected={ this.props.category === item.keyId }
                    visible={ this.props.visible }
                    separator={ item.separator }
                  >
                    { item.text }
                  </MenuItem>
                }
              }
            )
          }
        </VerticalList>
        <div className="menu__close">
          {
            Menu.ITEMS.map( ( item ) => {
                if ( !item.minVersion || !window.getAndroidCapsuleVersion() || window.getAndroidCapsuleVersion() >= item.minVersion ) {
                  return (
                    <Icon name={ item.icon }
                          separator={ item.separator }
                          selected={ this.props.category === item.keyId }
                    />
                  )
                }
              }
            )
          }
        </div>
      </ul>
    );
  }
}

export default Menu;
