import React, { Component } from 'react';
import { VerticalList } from 'react-key-navigation';
import Navigation from 'react-key-navigation';

import UserConnection from '../api/UserConnection'

import Wrapper from '../components/Wrapper';
import Input from '../components/Input';
import Button from '../components/Button';
import Title from '../components/Title';
import Text from '../components/Text';
import Image from '../components/Image';
import { goBack, goTo, isReturnEvent, PATH_HOME } from "../utils/NavigationUtils";


/**
 * The login page.
 */
class Login extends Component {
  /**
   * The navigation object.
   */
  navigation = null;

  /**
   * The error message for empty email.
   */
  static MESSAGE_ERROR_EMAIL = 'l\'adresse Email doit être renseignée';

  /**
   * The error message for empty password.
   */
  static MESSAGE_ERROR_PASSWORD = 'le mot de passe doit être renseigné';

  /**
   * The error message for bad password / id.
   */
  static MESSAGE_ERROR_VALUES = 'email et/ou mot de passe incorrect';

  /**
   * Set the isLoaded state to false.
   *
   * @param Object props
   *
   
   */
  constructor( props ) {
    super( props );

    this.state = {
      messageError: '',
      isLoaded: false,
    };

    this.verifyUser = this.verifyUser.bind( this );
    this.keyDownListener = this.keyDownListener.bind( this );
  }

  /**
   * Do the user connection.
   *
   * @param Object props
   *
   
   */
  async verifyUser() {
    let email = document.getElementById( 'input-email' ).value;
    let password = document.getElementById( 'input-password' ).value;

    if ( email.trim() === '' ) {
      this.setState( { messageError: Login.MESSAGE_ERROR_EMAIL } );
    } else if ( password.trim() === '' ) {
      this.setState( { messageError: Login.MESSAGE_ERROR_PASSWORD } );
    } else {
      if ( await UserConnection.connect( email, password ) ) {
        this.props.app.setState( { isLogged: true } );
        goTo( this.props.history, PATH_HOME );
      } else {
        this.setState( { messageError: Login.MESSAGE_ERROR_VALUES } );
      }
    }
  }

  /**
   * Opdn sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) ) {
      goBack( this.props.history );
    }
  }

  /**
   * Check if navigation is not null to avoid error.
   *
   
   */
  componentDidMount() {
    if ( this.navigation instanceof Navigation ) {
      this.setState( { isLoaded: true } );
    }
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Render the connexion page.
   *
   
   */
  render() {
    return (
      <Navigation ref={ ( el ) => this.navigation = el } class>
        <VerticalList className="login">
          <Title className="login__logo" level={ Title.LEVEL_1 } align={ Title.ALIGN_CENTER }>
            <Image alt='inlivestream' url={ require( '../assets/img/logo-white.png' ) }/>
          </Title>

          <Title 
            level={ Title.LEVEL_2 } 
            color={ Title.COLOR_SECONDARY } 
            align={ Title.ALIGN_CENTER }
            capitalize={ true }
          >se connecter</Title>

          <Title className="login__sentence" level={ Title.LEVEL_3 } align={ Title.ALIGN_CENTER } capitalize={ true } color={ Text.COLOR_SECONDARY }>
            renseigne ton email et ton mot de passe et accède à tes films de chasse,
            pêche et chasse sous-marine préférés !
          </Title>

          <Wrapper position={ Wrapper.POSITION_CENTERED }>
            <Input
              type="email"
              label="email"
              name="email"
              size={ Input.SIZE_BIG }
              navigation={ this.state.isLoaded ? this.navigation : null }
              autoFocus={ true }
              tabIndex={ 1 }
            />

            <Input
              type="password"
              label="mot de passe"
              name="password"
              size={ Input.SIZE_BIG }
              navigation={ this.state.isLoaded ? this.navigation : null }
              tabIndex={ 2 }
            />
            {
              this.state.messageError &&
              <Text state={ Text.STATE_ERROR } align={ Text.ALIGN_CENTER }>
                { this.state.messageError }
              </Text>
            }
          </Wrapper>

          <Wrapper
            sticky={ Wrapper.STICKY_BOTTOM }
            padding={ Wrapper.PADDING_NONE }
          >
            <Button
              navigation={ this.state.isLoaded ? this.navigation : null }
              block={ Button.BLOCK_FULL }
              url={ () => this.verifyUser() }
              history={ this.props.history }
              size={ Button.SIZE_GIANT }
              style={ Button.STYLE_SECONDARY }
            >go !</Button>
          </Wrapper>
        </VerticalList>
      </Navigation>
    )
  }
}

export default Login;
