import React, { Component } from 'react';
import Navigation, { HorizontalList } from 'react-key-navigation';

import CarouselFullScreen from '../components/CarouselFullScreen'
import Wrapper from '../components/Wrapper'
import Button from '../components/Button';
import Image from '../components/Image';
import { goTo, isReturnEvent, PATH_EXIT, PATH_LOGIN, PATH_SUBSCRIBE } from "../utils/NavigationUtils";


/**
 * This components is the landing page.
 *
 
 **/
class Landing extends Component {
  /**
   * The navigation object.
   */
  navigation = null;

  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false,
    };
    this.keyDownListener = this.keyDownListener.bind( this );
  }

  /**
   * Opdn sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) ) {
      goTo( this.props.history, PATH_EXIT );
    }
  }

  /**
   * Check if navigation is not null to avoid error.
   *
   
   */
  componentDidMount() {
    this.setState( { isLoaded: true } );
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Render the landing page.
   *
   
   */
  render() {
    return (
      <Navigation ref={ ( el ) => this.navigation = el }>
        <HorizontalList>
          {/* <CarouselFullScreen/> */}
          <Image url={ require( '../assets/img/concert_tmp.jpg' ) } size={ Image.SIZE_FULLSCREEN } />
          <Wrapper
            sticky={ Wrapper.STICKY_BOTTOM }
            padding={ Wrapper.PADDING_NONE }
          >
            <Button
              navigation={ this.state.isLoaded ? this.navigation : null }
              block={ Button.BLOCK_HALF }
              size={ Button.SIZE_GIANT }
              style={ Button.STYLE_PLAIN }
              url={ PATH_LOGIN }
              history={ this.props.history }
            >connexion</Button>
            <Button
              navigation={ this.state.isLoaded ? this.navigation : null }
              block={ Button.BLOCK_HALF }
              size={ Button.SIZE_GIANT }
              style={ Button.STYLE_PLAIN }
              url={ PATH_SUBSCRIBE }
              history={ this.props.history }
            >inscription</Button>
          </Wrapper>
        </HorizontalList>
      </Navigation>
    )
  }
}

export default Landing;
	