import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Focusable } from 'react-key-navigation';

import StringUtils from '../utils/StringUtils';
import CssClassUtils from '../utils/CssClassUtils';
import Text from "./Text";
import Button from './Button';
import Icon from './Icon';


/**
 * This components is a popup
 **/
class Popup extends Component { 
  static CSS_CLASS_BASE = 'popup';
  static OVERLAY = 'overlay';
  static CSS_CLASS_OVERLAY = Popup.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Popup.OVERLAY;

  /**
   * Initiate the ref and the states.
   * @param Object props
   */
  constructor( props ) {
    super( props );

    this.state = {}
  }

  /**
   * Return the css classes depending on the params.
   **/
  getCssClasses( overlay: boolean ) {
    let classes = Popup.CSS_CLASS_BASE;

    if ( overlay ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Popup.CSS_CLASS_OVERLAY;
    }

    return classes;
  }

  /**
   * Format the value of the button.
   *
   * @param object value The value inside of the title (images or text).
   *
   * @return object The value of the button.
   */
  getValueFormated( value: Object ) {
    return (StringUtils.isString( this.props.children )) ? StringUtils.toCapitalize( this.props.children ) : this.props.children;
  }

  /**
   * Render the button.
   *
   * @return Render object The button.
   *
   
   */
  render() {
    return (
      <div className={ this.getCssClasses( this.props.overlay ) }>
        <div className={ Popup.CSS_CLASS_BASE + '__content' }>
          <Button 
            size={ Button.SIZE_MEDIUM } 
            navigation={ this.props.navigation }
            history={ this.props.history }
            url={ this.props.closeFunction }
          >
            <Icon name="quit" color={ Icon.COLOR_SECONDARY }/>
          </Button>
          { this.props.children }
        </div>
      </div>
    )
  }
}

export default Popup;