import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StringUtils from '../utils/StringUtils';
import CssClassUtils from '../utils/CssClassUtils';


/**
 * This components is text.
 *
 
 **/
class Text extends Component {
  /**
   * The state error.
   **/
  static STATE_ERROR = 'error';

  /**
   * The state error.
   **/
  static ALIGN_CENTER = 'center';

  /**
   * The state center
   **/
  static ALIGN_JUSTIFY = 'justify';

  /**
   * The secondary color
   **/
  static COLOR_SECONDARY = 'secondary';  

  /**
   * The important color
   **/
  static COLOR_IMPORTANT = 'important';

  /**
   * The grey color
   **/
  static COLOR_GREY = 'grey';

  /**
   * Display inline
   */
  static DISPLAY_INLINE = 'inline'

  /**
   * The shadow
   **/
  static SHADOW = 'shadow';

  /**
   * Text large
   */
  static SIZE_LARGE = 'large';

  /**
   * Text small
   */
  static SIZE_SMALL = 'small';

  /**
   * Text tiny
   */
   static SIZE_TINY = 'tiny';

  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'text';
  
  /**
   * The base css class name.
   **/
  static NO_LINEHEIGHT = 'no-lineheight';

  /**
   * The weight bold class name.
   */
  static WEIGHT_BOLD = 'bold';

  /**
   * The weight light class name.
   */
  static WEIGHT_LIGHT = 'light';

  /**
   * The error css class name.
   **/
  static CSS_CLASS_STATE_ERROR = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.STATE_ERROR;

  /**
   * The center css class name.
   **/
  static CSS_CLASS_ALIGN_CENTER = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.ALIGN_CENTER;

  /**
   * The justify css class name.
   **/
  static CSS_CLASS_ALIGN_JUSTIFY = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.ALIGN_JUSTIFY;

  /**
   * The secondary color css class name.
   **/
  static CSS_CLASS_COLOR_SECONDARY = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.COLOR_SECONDARY;

  /**
   * The grey color css class name.
   **/
  static CSS_CLASS_COLOR_GREY = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.COLOR_GREY;

  /**
   * The important color css class name.
   **/
  static CSS_CLASS_COLOR_IMPORTANT = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.COLOR_IMPORTANT;

  /**
   * The display inline css class name
   */
  static CSS_CLASS_DISPLAY_INLINE = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.DISPLAY_INLINE;

  /**
   * The text shadow css class name
   */
  static CSS_CLASS_SHADOW = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.SHADOW;

  /**
   * The size large css class name.
   **/
  static CSS_CLASS_SIZE_LARGE = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.SIZE_LARGE;

  /**
   * The siz small css class name
   */
  static CSS_CLASS_SIZE_SMALL = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.SIZE_SMALL;

    /**
   * The size tiny css class name
   */
  static CSS_CLASS_SIZE_TINY = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.SIZE_TINY;

  /**
   * The no lineheight css class name
   */
  static CSS_CLASS_NO_LINEHEIGHT = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.NO_LINEHEIGHT;

  /**
   * The weight bold css class name
   */
  static CSS_CLASS_WEIGHT_BOLD = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.WEIGHT_BOLD;

  /**
   * The weight light css class name
   */
  static CSS_CLASS_WEIGHT_LIGHT = Text.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Text.WEIGHT_LIGHT

  /**
   * Return the css classes depending on the props.
   *
   * @return string The css classes.
   **/
  getCssClasses( state, align, color, shadow, size, lineheight, display, weight ) {
    let classes = ( this.props.className ) ?
      this.props.className + CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_BASE :
      Text.CSS_CLASS_BASE;

    switch ( state ) {
      case Text.STATE_ERROR:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_STATE_ERROR;
        break;
    }

    switch ( align ) {
      case Text.ALIGN_CENTER:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_ALIGN_CENTER;
        break;
      case Text.ALIGN_JUSTIFY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_ALIGN_JUSTIFY;
        break;
    }

    switch ( color ) {
      case Text.COLOR_SECONDARY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_COLOR_SECONDARY;
        break;
      case Text.COLOR_GREY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_COLOR_GREY;
        break;        
      case Text.COLOR_IMPORTANT:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_COLOR_IMPORTANT;
        break;
    }

    if ( shadow ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_SHADOW;
    }

    switch ( size ) {
      case Text.SIZE_SMALL:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_SIZE_SMALL;
        break;
      case Text.SIZE_LARGE:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_SIZE_LARGE;
        break;
      case Text.SIZE_TINY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_SIZE_TINY;
        break;
    }

    if ( !lineheight ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_NO_LINEHEIGHT;
    }

    switch ( display ) {
      case Text.DISPLAY_INLINE:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_DISPLAY_INLINE;
    }

    switch ( weight ) {
      case Text.WEIGHT_BOLD:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_WEIGHT_BOLD;
        break;
      case Text.WEIGHT_LIGHT:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Text.CSS_CLASS_WEIGHT_LIGHT;
        break;
    }
    return classes;
  }

  /**
   * Format the value of the text.
   *
   * @return object The value of the text.
   */
  getValueFormated( text ) {
    if ( this.props.maxLen ) {
      text = text.substring(0, this.props.maxLen - 3 ) + '...' ;
    }
    if ( this.props.uppercase ) return StringUtils.toUpperCase( text );
    if ( this.props.capitalize ) return StringUtils.toCapitalize( text );
    return text;
  }

  /**
   * Render the text.
   */
  render() {
    const content = StringUtils.isString( this.props.children ) ?
      this.getValueFormated( this.props.children ) :
      this.props.children;

    const { state, align, color, shadow, size, lineheight, display, weight } = this.props;

    return (
      <p className={ this.getCssClasses( state, align, color, shadow, size, lineheight, display, weight ) }>
        { content }
      </p>

    )
  }
}

/**
 *
 * @type {{
 *   color: Requireable<string>,
 *   shadow: Requireable<boolean>,
 *   state: Requireable<string>,
 *   align: Requireable<string>
 * }}
 */
Text.propTypes = {
  state: PropTypes.oneOf( [
    Text.STATE_ERROR,
  ] ),
  align: PropTypes.oneOf( [
    Text.ALIGN_CENTER,
    Text.ALIGN_JUSTIFY,
  ] ),
  color: PropTypes.oneOf( [
    Text.COLOR_SECONDARY,
    Text.COLOR_GREY,
    Text.COLOR_IMPORTANT
  ] ),
  shadow: PropTypes.bool,
  size: PropTypes.oneOf( [
    Text.SIZE_SMALL,
    Text.SIZE_LARGE,
    Text.SIZE_TINY,
  ] ),
  capitalize: PropTypes.bool,
  lineheight: PropTypes.bool,
  maxLen: PropTypes.number,
  display: PropTypes.oneOf( [
    Text.DISPLAY_INLINE
  ] ),
  weight: PropTypes.oneOf( [
    Text.WEIGHT_BOLD,
    Text.WEIGHT_LIGHT,
  ] )
};

Text.defaultProps = {
  capitalize: true,
  lineheight: true,
  uppercase: false,
};

export default Text;