import { func } from "prop-types";
import StringUtils from "./StringUtils";

const version = '210514';
const domain = window.location.hostname;
const providers = [ 'vewd', 'zeasn', 'lg' ];

/**
 *
 * @returns {string}
 */
export function getDevice() {
  const dev = localStorage.getItem( 'device' );
  if ( !StringUtils.isEmpty( dev ) ) return dev;

  let prov;
  providers.forEach( provider => {
    if ( domain.includes( provider ) ) {
      prov = provider;
    }
  } );
  if ( !StringUtils.isEmpty( prov ) ) return prov;

  //Default
  return 'tv';
}

/**
 * Returns device id for identification in data_collect
 * @returns {string}
 */
export function getDeviceId() {
  return 'app_tv_' + getDevice() + '_v' + version;
}
