export const PATH_ROOT = '/';
export const PATH_HOME = '/home';
export const PATH_PURCHASE = '/purchase';
export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_EXIT = '/exit';
export const PATH_SUBSCRIBE = '/subscription';
export const PATH_SEARCH = '/search';
export const PATH_DETAIL = '/detail';
export const PATH_PLAY = '/play';
export const PATH_READ = '/read';

export const PATH_PARAMS_FILM_ID = '/:FilmID';
export const PATH_PARAMS_ACTION_QUIT = '/:action(quit)?';
export const PATH_PARAMS_PREVIEW = '/:mode(preview)?';

export const customHistory = [];

export function isReturnEvent( evt ): boolean {
  if (
    evt.keyCode === 27 ||
    evt.keyCode === 10009 || /* Samsung Remote */
    evt.keyCode === 461   /* LG Magic Remode */
  ) {
    evt.preventDefault();
    evt.stopPropagation();
    return true;
  }
  return false;
}

export function goTo( history: Object, url: String, replace: boolean = false ): void {
  if ( replace && 'replace' in history ) {
    customHistory[ customHistory.length - 1 ] = url;
    history.replace( url );
  } else {
    customHistory.push( url );
    history.push( url );
  }
}

export function goBack( history: Object ): void {
  customHistory.pop();
  history.goBack();
}