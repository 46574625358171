import React, { Component } from 'react';
import axios from 'axios';
import Navigation, { VerticalList, HorizontalList, Grid } from 'react-key-navigation';
import { withLastLocation } from 'react-router-last-location';

import Sidebar from '../components/SideBar';
import Wrapper from '../components/Wrapper';
import Input from '../components/Input';
import ListItem from '../components/ListItem';
import Text from '../components/Text';
import Loader from '../components/Loader';

import { getImageUrl, typeThumb } from '../api/ImageManager';
import { getSearch } from '../api/ApiManager';
import { goBack, goTo, isReturnEvent, PATH_SEARCH } from "../utils/NavigationUtils";
import App from "../App";


/**
 * Component corresponding to the search page
 */
class Search extends Component {
  /**
   * Numbers of columns for the search result display
   * @type {number}
   */
  static GRID_COLUMNS = 4;

  /**
   * Number of chars minimum to start search
   */
  static SEARCH_MIN_LEN = 3;

  /**
   * Contains search results
   * @type {[]}
   */
  images = [];

  /**
   * Contains the reference of the result search scrollable div
   */
  scrollableDiv;

  /**
   * Constructor of the Search class
   * @param props
   */
  constructor( props ) {
    super( props )

    this.state = {
      isSearchStarted: false,
      inputActive: false,
      mouseFocus: false,
      actualIndex: null,
      isLoaded: false,
      pageLoaded: true,
      search: '',
      activeItem: null,
    };

    this.navigation = React.createRef();
    this.scrollableDiv = React.createRef();

    this.keyDownListener = this.keyDownListener.bind( this );
  }

  /**
   * Request search on API
   * @param value
   * @returns {Promise<void>}
   */
  async search( value: string ) {
    return await axios.get( getSearch( value ) ).then( ( resp ) => {
      const mesImages = [];
      if ( !resp.data.data.is_empty ) {
        Object.values( resp.data.data ).forEach( ( item ) => {
          if ( item ) {
            mesImages.push( {
              uri: getImageUrl( item.transaction, typeThumb ),
              aff_titre: item.aff_titre,
              titre: item.titre,
              transaction: item.transaction,
              item: item
            } );
          }
        } )
      }
      return mesImages;
    } ).catch( err => {
      return false;
    } );
  }

  /**
   * Check if request has to be done
   * @param text
   * @returns {[]}
   */
  updateSearch( text: string ) {
    this.setState( { isLoaded: false } );

    if ( text && text.length >= Search.SEARCH_MIN_LEN ) { //Min 3 characters
      this.setState( {
        isSearchStarted: true,
        activeItem: null,
      } );

      this.search( text.trim() ).then( images => {
        this.images = images;
        this.setState( { isSearchStarted: false } );
      } ).catch( err => {
        this.setState( { isSearchStarted: false } );
      } );

    } else {
      this.images = [];
    }
    this.setState( { isLoaded: true } );
  };

  /**
   * Change actual item index focus
   * @param index
   */
  setActualIndex( index: number ) {
    this.setState( { actualIndex: index } );
  }

  /**
   * Change state inputActive value
   * @param active
   */
  setInputActive( active: boolean ) {
    this.setState( { inputActive: active } );
  }

  /**
   * Set mouseFocus state to given state
   * @param active
   */
  setMouseFocus( active: boolean ) {
    this.setState( { mouseFocus: active } );
  }

  /**
   * Set activeItem state to given index or null
   * @param index
   */
  setItemActive( index: number = null ) {
    this.setState( { activeItem: index } );
  }

  /**
   * Opdn sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) && this.sidebar && this.navigation && !this.sidebar.state.active ) {
      this.sidebar.setActive( true );
      this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
      goTo( this.props.history, PATH_SEARCH, true );
    }
  }

  /**
   * Restore last search from session if comeback from detail page & set state loaded to true
   */
  componentDidMount() {
    const save = JSON.parse( sessionStorage.getItem( 'search' ) );
    if ( this.props.lastLocation && this.props.lastLocation.pathname.includes( 'detail' ) ) {
      this.setState( { search: save.value ? save.value : '' } );
    }
    this.setState( { isLoaded: true, pageLoaded: true } );
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  shouldComponentUpdate( nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any ): boolean {
    if ( this.props.match.params.action === 'quit' && !nextProps.match.params.action ){
      return false;
    }
    return true;
  }

  componentDidUpdate( prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS ) {
    if ( prevState.search !== this.state.search ) {
      this.updateSearch( this.state.search );
      sessionStorage.setItem( 'search', JSON.stringify( {
        'value': this.state.search,
        'activeItem': this.state.activeItem,
      } ) );
    }

    if ( this.props.match.params.action === 'quit' ) {
      this.keyDownListener( new KeyboardEvent( 'keydown', { keyCode: 27 } ) );
    }
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Render method of the Search class
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Navigation ref={ ( el ) => { this.navigation = el } }>
        <div
          ref={ ( el ) => this.searchPage = el }
          className="search"
        >
          {
            this.state.pageLoaded &&
            <HorizontalList>
              <Sidebar
                ref={ ( el ) => this.sidebar = el }
                category="search"
                history={ this.props.history }
                navigation={ this.navigation }
                parent={ this }
              />
              <VerticalList>
                <Wrapper>
                  <Input
                    type="text"
                    label="search"
                    name="search"
                    navigation={ this.navigation }
                    onChange={ ( text ) => this.setState( { search: text.target.value } ) }
                    focusOnLoad={ true }
                    autoFocus={ true }
                    withLabel={ false }
                    value={ this.state.search }
                    parent={ this }
                    autoComplete="off"
                  />
                </Wrapper>

                {
                  App.DEVICE !== 'desktop' &&
                  <div className={ 'search__content__overlay' + (!this.state.inputActive ? '--hide' : '') }/>
                }
                <div className="search__content" ref={ this.scrollableDiv }>
                  {
                    !this.state.isSearchStarted && this.state.search.length <= 2 &&
                    <Text align={ Text.ALIGN_CENTER }>
                      Tape un/des mots clefs correspondant aux film que tu recherches.<br/>Tu peux rechercher
                      directement le titre d'un film, un lieu, un type de gibier/poisson, une technique, un
                      personnage...
                    </Text>
                  }
                  {
                    !this.state.isSearchStarted && this.images.length <= 0 && this.state.search.length >= Search.SEARCH_MIN_LEN &&
                    <Text align={ Text.ALIGN_CENTER }>Aucun résultat</Text>
                  }
                  {
                    !this.state.isSearchStarted && this.images.length > 0 && this.state.search.length >= Search.SEARCH_MIN_LEN &&
                    <Grid
                      ref={ ( el ) => this.grid = el }
                      columns={ Search.GRID_COLUMNS }
                      rows={ Math.ceil( this.images.length / Search.GRID_COLUMNS ) }
                    >
                      {
                        this.images.map(
                          ( item, i ) => {
                            return (
                              <ListItem
                                redirectTo={ ( url ) => goTo( this.props.history, url ) }
                                goBack={ () => goBack( this.props.history ) }
                                navigation={ this.navigation }
                                parentList={ this }
                                parentCategory={ this }
                                categoryIndex={ 0 }
                                itemIndex={ i }
                                setThisItemActive={ () => this.setItemActive( i ) }
                                lazyLoadingShift={ 20 }
                                viewport={ this.scrollableDiv.current }
                                item={ item }
                                verticalAutoScroll
                                vGap={ 40 }
                              />
                            )
                          }
                        )
                      }
                    </Grid>
                  }
                  {
                    this.state.isSearchStarted && <Loader/>
                  }
                </div>
              </VerticalList>
            </HorizontalList>
          }
        </div>
      </Navigation>
    );
  }
}

export default withLastLocation( Search );