import React, { Component } from "react";
import App from "../App";


class Exit extends Component {
  constructor( props ) {
    super( props )
    window.close();
    if (  [ 'amazon', 'android' ].includes( App.DEVICE ) ) window.closeApp();
  }
  render(){
    return(<></>)
  }
}

export default Exit;