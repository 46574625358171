import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import Landing from './pages/Landing';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Details from './pages/Detail';
import PlayerSafe from './components/PlayerFireStick';
import MyPlayer from './components/Player';
import Catalog from './pages/Catalog';
import Reader from './pages/Reader';
import Subscription from './pages/Subscription';
import Exit from './pages/Exit';
import SplashScreen from './components/SplashScreen'
import { getDevice } from './utils/DeviceUtils';
import { 
  customHistory, 
  goTo,
  PATH_ROOT, 
  PATH_HOME, 
  PATH_LOGIN, 
  PATH_LOGOUT, 
  PATH_EXIT, 
  PATH_SUBSCRIBE, 
  PATH_DETAIL, 
  PATH_PLAY, 
  PATH_PARAMS_FILM_ID, 
  PATH_PARAMS_ACTION_QUIT, 
  PATH_PURCHASE,
  PATH_READ,
  PATH_PARAMS_PREVIEW
} from './utils/NavigationUtils';

import './sass/themes/inlivestream/v3/main.scss';
import Purchase from './pages/Purchases';


const device = ( new URLSearchParams( window.location.search ) ).get( 'device' );
localStorage.setItem( 'device', device );


class App extends Component {
  static DEVICE = getDevice();

  constructor( props ) {
    super( props )

    this.state = {
      isLogged: false,
      isFirstDisplay: true,
      withSplasher: false,
    };
    this.router = React.createRef();
  }

  componentWillMount() {
    var mesData = localStorage.getItem( 'LOGIN_TOKEN' );

    if ( !mesData ) {
      this.setState( { isLogged: false } )
    } else {
      this.setState( { isLogged: true } )
    }

    /** todo put to true for prod, false in dev **/
    this.setState( { withSplasher: false } );
  }

  // componentDidMount() {
  //   window.addEventListener('keydown', e => {
  //     console.log('keydown', e.keyCode);
  //   });
    
  //   window.addEventListener('keypress', e => {
  //     console.log('keypress', e.keyCode);
  //   });
  //   window.addEventListener('keydown', e => {
  //     console.log('keyup', e.keyCode);
  //   });
  // }

  render() {
    const hostname = window.location.hostname.replace( '.zone300.tv', '' );

    //TODO optimize routes declaration ???
    return (
      <Fragment key='component-router'>
        { this.state.withSplasher &&
        <SplashScreen parent={ this }/>
        }

        <Router ref={ this.router }>
          <LastLocationProvider>
            <Switch>
              <Route exact path={ PATH_ROOT } render={ ( props ) => {
                if ( !this.state.isLogged ) {
                  return <Landing { ...props } hostname={ hostname }/>;
                } else {
                  if ( customHistory.length > 0 ) {
                    goTo( props.history, PATH_EXIT, true );
                  } else {
                    goTo( props.history, PATH_HOME );
                  }
                }
              } }
              />
              <Route exact path={ PATH_SUBSCRIBE } render={ ( props ) => <Subscription { ...props } hostname={ hostname } /> }/>
              <Route exact path={ PATH_LOGIN } render={ ( props ) => {
                if ( !this.state.isLogged ) {
                  return <Login { ...props } app={ this }/>;
                } else {
                  goTo( props.history, PATH_HOME, true );
                }
              } }
              />
              <Route exact path={ PATH_HOME + PATH_PARAMS_ACTION_QUIT } render={ ( props ) => {
                if ( this.state.isLogged ) {
                  return <Catalog { ...props } app={ this }/>;
                } else {
                  goTo( props.history, PATH_LOGIN, true );
                }
              } }
              />
              <Route exact path={ PATH_PURCHASE + PATH_PARAMS_ACTION_QUIT } render={ ( props ) => {
                if ( this.state.isLogged ) {
                  return <Purchase { ...props } />;
                } else {
                  goTo( props.history, PATH_LOGIN, true );
                }
              } }
              />
              <Route path={ PATH_LOGOUT } render={ ( props ) => <Logout app={ this } { ...props } /> }/>
              <Route path={ PATH_EXIT } render={ ( props ) => <Exit/> }/>
              <Route exact path={ PATH_DETAIL + PATH_PARAMS_FILM_ID } render={ ( props ) => {
                if ( this.state.isLogged ) {
                  return <Details { ...props } />;
                } else {
                  goTo( props.history, PATH_LOGIN, true );
                }
              } }
              />
              <Route exact path={ PATH_PLAY + PATH_PARAMS_FILM_ID } render={ ( props ) => {
                if ( this.state.isLogged ) {
                  return ( [ 'amazon', 'android' ].includes( App.DEVICE ) ) ?
                    <PlayerSafe { ...props } hostname={ hostname }/> : <MyPlayer  { ...props } hostname={ hostname }/>;
                } else {
                  goTo( props.history, PATH_LOGIN, true );
                }
              } }
              />
              <Route exact path={ PATH_READ + PATH_PARAMS_FILM_ID + PATH_PARAMS_PREVIEW } render={ ( props ) => {
                if ( this.state.isLogged ) {
                  return <Reader { ...props }/>;
                } else {
                  goTo( props.history, PATH_LOGIN, true );
                }
              } }
              />
            </Switch>

          </LastLocationProvider>
        </Router>
      </Fragment>
    )
  }
}


export default App;