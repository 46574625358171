import React, { Component } from 'react';
import Image from '../components/Image';
import Navigation from 'react-key-navigation';
import Wrapper from '../components/Wrapper';
import Button from '../components/Button';
import Icon from '../components/Icon';
import Title from '../components/Title';
import Text from '../components/Text';
import StringUtils from '../utils/StringUtils';
import { goBack, isReturnEvent } from "../utils/NavigationUtils";
import { apiDomain } from "../api/ApiManager";


/**
 * This components is the inscription page
 
 **/
class Subscription extends Component {
  /**
   * The navigation object.
   */
  navigation = null;

  /**
   * Reference for the back button
   */
  backBtn;

  /**
   * Constructor method of the Inscription component
   * @param props
   */
  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false
    }
    this.backBtn = React.createRef();
    this.keyDownListener = this.keyDownListener.bind( this );
  }

  /**
   * Go back on return event (esc)
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) ) {
      goBack( this.props.history );
    }
  }

  componentDidMount() {
    this.setState( { isLoaded: true } );
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Render method of the Inscription component
   
   */
  render() {
    return (
      <Navigation ref={ ( el ) => { this.navigation = el } }>
        <div className="inscription">
          <Image url={ require( '../assets/img/bg-inlivestream.jpg' ) } size={ Image.SIZE_FULLSCREEN }/>
            {
              <Wrapper overlay={ Wrapper.OVERLAY_GRADIENT_BCKGND }>
                <Button
                  ref={ (el) => { this.backBtn = el } }
                  navigation={ this.navigation }
                  history={ this.props.history }
                >
                  <Icon name="arrowLeft" color={ Icon.COLOR_SECONDARY }/>
                </Button>
                <Title level={ Title.LEVEL_1 }>
                  <Image alt='Inlivestream' url={ require( '../assets/img/logo-white.png' ) }/>
                </Title>
                <Title level={ Title.LEVEL_2 } capitalize={ true }>
                  pour continuer l'inscription rendez-vous sur
                </Title>
                <Text size={ Text.SIZE_LARGE } capitalize={ false }>{ apiDomain }</Text>
                <Text capitalize={ false }>(Cliquer sur <em>S'identifier</em> puis <em>Créer un compte</em>)</Text>
                <Image url={ apiDomain + 'assets/tv/registration_qrcode.png' } alt="qr code" withLoader={ false }/>
              </Wrapper>
            }
        </div>
      </Navigation>
    );
  }
}

export default Subscription;
