import { Component } from 'react';

/**
 * Utils containing CSS classes variables used by components
 */
class CssClassUtils extends Component {
  /**
   * The css classes separator.
   */
  static CSS_CLASS_SEPARATOR = ' ';

  /**
   * The css class state separator.
   */
  static CSS_CLASSNAME_STATE_SEPARATOR = '--';

  /**
   * The css class block separator.
   */
  static CSS_CLASSNAME_BLOCK_SEPARATOR = '__';

  /**
   * The CSS class active state
   */
  static CSS_CLASS_STATE_ACTIVE = 'active';

  /**
   * The CSS class active state
   */
  static CSS_CLASS_STATE_FOCUS = 'focus';
}

export default CssClassUtils;