import React from 'react';
import PropTypes from 'prop-types';
import StringUtils from '../utils/StringUtils';
import CssClassUtils from "../utils/CssClassUtils";


/**
 * This components is the label for inputs.
 *
 
 **/
class Label extends React.Component {
  /**
   * The default value.
   **/
  static VALUE_DEFAULT = 'remplissez ce champs';

  /**
   * The focused state.
   */
  static STATE_FOCUS = 'focus';

  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'form__label';

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_FOCUS = Label.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Label.STATE_FOCUS;

  /**
   * Return the css classes depending on the params).
   *
   * @param bool isActive If the label is active or no.
   * @return string The css classes.
   *
   
   **/
  getCssClasses( isActive: bool ) {
    let classes = Label.CSS_CLASS_BASE;

    if ( isActive ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Label.CSS_CLASS_STATE_FOCUS;
    }

    return classes;
  }

  /**
   * Render the Label.
   *
   
   */
  render() {
    return (
      <label
        className={ this.getCssClasses( this.props.isActive ) }
        htmlFor={ 'input-' + this.props.name }
      >
        { StringUtils.toCapitalize( this.props.value ) }
      </label>
    )
  }
}

/**
 * @props string name The name attribute of the label.
 * @props string value The value.
 * @props bool isActive If the corresponding input is active.
 **/
Label.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isActive: PropTypes.bool,
};
/**
 *
 * @type {{isActive: boolean, value: string}}
 */
Label.defaultProps = {
  value: Label.VALUE_DEFAULT,
  isActive: false,
};

export default Label;