import React, { Component, Fragment } from 'react';
import Navigation, { Focusable } from 'react-key-navigation';
import PropTypes from 'prop-types';

import Catalog from '../pages/Catalog';
import Text from './Text';
import Title from './Title';
import Icon from './Icon';
import Image from './Image';
import Search from '../pages/Search';
import Loader from './Loader';
import CssClassUtils from '../utils/CssClassUtils';
import StringUtils from '../utils/StringUtils';
import { getImageUrl, typeThumb, visual1 } from '../api/ImageManager';
import MenuItem from './MenuItem';


class ListItem extends Component {
  /**
   * The basee CSS class name
   */
  static CSS_CLASS_BASE = 'listitem';

  static TYPE_DEFAULT = 'default';

  static TYPE_GRID = 'grid';

  /**
   * The CSS Class active
   */
  static CSS_CLASS_ACTIVE = ListItem.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + CssClassUtils.CSS_CLASS_STATE_ACTIVE;

  static CSS_CLASS_TYPE_GRID = ListItem.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + ListItem.TYPE_GRID;

  /** 
   * Corresponds to the zoom applied to the thumbnails during focusing
   */
  static THUMBNAIL_ZOOM = 0.05;

  /**
   * Url of the film poster
   */
  poster;

  /**
   * Constructor of the ListItem class
   * @param props
   */
  constructor( props ) {
    super( props );
    this.state = {
      isLoaded: false,
      active: false,
      imageStatus: false,
      titleVisible: true,
    };

    this.poster = getImageUrl( this.props.item.transaction, typeThumb, visual1 );
    this.displayItem = this.createDisplayableObject( this.props.item );
    this.itemImg = React.createRef();
    this.item = React.createRef();

    this.onFocus = this.onFocus.bind( this );
    this.onBlur = this.onBlur.bind( this );
    this.onEnterDown = this.onEnterDown.bind( this );
    this.isInViewport = this.isInViewport.bind( this );
    this.onMouseEnter = this.onMouseEnter.bind( this );

    
  }

  createDisplayableObject( item ) {
    const newItem = {};
    
    switch( item.type_product ) {
      case 'Concert':
        newItem.title = item.title;
        newItem.productType = item.type_product;
        newItem.iconName = StringUtils.toLowerCase( item.type_product );
        break;
      case 'Livre audio':
        newItem.title = item.title_book;
        newItem.productType = item.type_product;
        newItem.iconName = 'book';
        break;
    }

    newItem.isSpecialOffer = StringUtils.isString( item.title_description );
    newItem.shortDescription = item.description_thumbnail;
    console.log(item.date_available);//TODO Del 
    newItem.dateAvailable = StringUtils.isString( item.title_available ) ? new Date( item.date_available * 1000 ) : false;
    console.log(newItem.dateAvailable);//TODO Del 
    newItem.isLive = Date.now() > item.date_available && Date.now() < item.date_expiry;

    return newItem;
  }

  /**
   * Horizontal or/and vertical scroll if the focus item not in wiewport
   */
  isInViewport( horizontal: boolean, horizontalGap: number = 0, vertical: boolean = false, verticalGap: number = 0 ) {
    const itemContent = this.item;

    if ( horizontal ) { //Horizontal scroll
      const list = itemContent.parentNode.parentNode; 
      const itemLeft = itemContent.getBoundingClientRect().left - itemContent.offsetWidth * 0.05;
      const itemRight = itemContent.getBoundingClientRect().right + itemContent.offsetWidth * 0.05;
      const { left , right } = list.getBoundingClientRect();

      if ( itemRight > right ) {
        list.scrollLeft += itemRight - right + horizontalGap;
      } else if ( itemLeft < left ) {
        list.scrollLeft += itemLeft - left /*+ horizontalGap*/;
      }
    }

    if ( vertical ) { //Vertical scroll
      const { viewport } = this.props;

      const itemTop = itemContent.getBoundingClientRect().top - itemContent.offsetHeight * 0.05;
      const itemBottom = itemContent.getBoundingClientRect().bottom + Image.offsetHeight * 0.05;
      const { top, bottom } = viewport.getBoundingClientRect();

      if ( itemBottom > bottom ) {
        viewport.scrollTop += itemBottom - bottom + verticalGap;
      } else if ( itemTop < top ) {
        viewport.scrollTop += itemTop - top /*- verticalGap*/;
      }
    }
  }

  /**
   * Returns if true the component is in the screen or close to be
   * @returns {boolean}
   */
  isItemVisible(): boolean {
    const { activeItem } = this.props.parentList.state;
    return this.props.itemIndex > activeItem - this.props.lazyLoadingShift &&
      this.props.itemIndex < activeItem + this.props.lazyLoadingShift;
  }

  /**
   * @TODO
   */
  handleImageLoaded() {
    if ( this.props.item.aff_titre !== '1' ) {
      this.setState( { titleVisible: false } );
    }
    this.setState( { imageStatus: true } );
  }

  /**
   * @TODO
   */
  handleImageErrored() {
    this.setState( { imageStatus: false } );
  }

  /**
   * Change active state to false on blur event
   */
  onBlur() {
    this.setState( { active: false } );
  }

  /**
   * Change some states chen item focus
   * @param index
   * @param evt
   * @returns {boolean}
   */
  onFocus( index, evt ) {
    if ( !this.state.isLoaded ) {
      return false;
    }
    this.props.parentCategory.setActualIndex( index );
    this.props.parentCategory.setMouseFocus( false );

    this.setState( { active: true } );
    this.props.setThisItemActive();
    this.isInViewport( this.props.horizontalAutoScroll, this.props.hGap, this.props.verticalAutoScroll, this.props.vGap );
  }

  /**
   * Focus on item and set state mouseFocus to true
   */
  onMouseEnter() {
    if ( this.props.navigation ) {
      this.props.navigation.forceFocus( this.itemFocusable.focusableId );
    }
    this.props.parentCategory.setMouseFocus( true );
  }

  /**
   * Redirect to detail page
   * @param event
   * @param navigation
   * @param item
   */
  onEnterDown( event, navigation, item ) {
    if ( this.state.active ) {
      this.props.redirectTo( '/detail/' + item.transaction );
    }
  }

  /**
   * Return the classname for the ListItem component
   * @param active
   * @returns {string}
   */
  getClasses( active: boolean, type: string ) {
    let classes = ListItem.CSS_CLASS_BASE;

    classes += active ? CssClassUtils.CSS_CLASS_SEPARATOR + ListItem.CSS_CLASS_ACTIVE : '';

    switch ( type ) {
      case ListItem.TYPE_GRID: 
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + ListItem.CSS_CLASS_TYPE_GRID;
    }

    return classes;
  }

  /**
   * Set state loaded after component mount
   */
  componentDidMount() {
    this.setState( { isLoaded: true, } );
  }

  /**
   * Prevent rendering component if not in screen
   * @param nextProps
   * @param nextState
   * @param nextContext
   * @returns {boolean}
   */
  shouldComponentUpdate( nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any ): boolean {
    return this.isItemVisible();
  }

  /**
   * Render method of the ListItem class
   * @returns {JSX.Element}
   */
  render() {
    const { productType, iconName, title, dateAvailable, isSpecialOffer, shortDescription } = this.displayItem;

    return (
      <Focusable
        className={ this.getClasses( this.state.active, this.props.type ) }
        ref={ ( el ) => this.itemFocusable = el }
        onMouseEnter={ this.onMouseEnter }
        onFocus={ ( i, evt ) => this.onFocus( i, evt ) }
        onBlur={ this.onBlur }
        onClick={ ( e, n ) => this.onEnterDown( e, n, this.props.item ) }
        onEnterDown={ ( e, n ) => this.onEnterDown( e, n, this.props.item ) }
      >
        {
          this.state.isLoaded &&
          <div 
            className="listitem__content"
            ref={ el => this.item = el }>
            <div className="listitem__thumb">
              <img
                ref={ ( el ) => this.itemImg = el }
                src={ this.poster }
                alt={ this.props.item.titre }
                onLoad={ this.handleImageLoaded.bind( this ) }
                onError={ this.handleImageErrored.bind( this ) }
                className="listitem__thumb__img"
              />
              {
                this.state.titleVisible && <Text captitalize={ false } shadow={ true }>{ this.props.item.titre }</Text>
              }
              {
                !this.state.imageStatus && <Loader color="#FFF"/>
              }
              {
                Date.now() >= this.props.item.date_available && Date.now() <= this.props.item.date_expiry &&
                <Image url={ require( '../assets/icons/live.png' ) } className="icon-live"/>
              }
              {
                //TODO condition
                this.props.item.is_purchased &&
                <Icon name={ Icon.NAME_SHOP_BASKET } className="icon-purchased"/>
              }
            </div>
            {
              this.props.withText &&
              <div className="listitem__text">
                <Text className="listitem__text__category" size={ Text.SIZE_SMALL } color={ Text.COLOR_PRIMARY } captitalize={ false }>
                  { StringUtils.toUpperCase( productType ) }
                  <Icon className="listitem__text__category__icon" name={ iconName }/>
                </Text>

                <Title
                  className="listitem__text__title"
                  level={ Title.LEVEL_4 }
                  color={ Title.COLOR_SECONDARY }
                  captitalize={ false }
                >
                  { title }
                </Title>
                {
                  this.props.type === ListItem.TYPE_DEFAULT &&
                  <Fragment>
                    {
                      dateAvailable &&
                      <Text 
                        className="listitem__text__date-available"
                        size={ Text.SIZE_SMALL } 
                        color={ Text.COLOR_IMPORTANT }
                        captitalize={ false }
                      >
                        { 'Disponible le ' + dateAvailable.getDate() + '/' + StringUtils.formatInteger( (dateAvailable.getMonth() + 1).toString(), 2 ) + '/' + dateAvailable.getFullYear() }
                      </Text>
                    }
                    {
                      isSpecialOffer &&
                      <Text captitalize={ false } className="listitem__text__offer" size={ Text.SIZE_SMALL } color={ Text.COLOR_IMPORTANT }>Offre spéciale en pré-commande</Text>
                    }
                    <Text 
                      className="listitem__text__description" 
                      size={ Text.SIZE_SMALL } 
                      color={ Text.COLOR_GREY } 
                      lineheight={ false } 
                      maxLen={ 130 }
                      captitalize={ false }
                    >
                      { shortDescription }
                    </Text>
                  </Fragment>
                }
              </div>
            }
          </div>
            
        }
      </Focusable>
    );
  }
}


export default ListItem;

/**
 * item: movie,
 * redirectTo: function to change url,
 * goBack: function to go back in history,
 * lazyLoadingShift: number of items to render compared to the active item,
 * parentList: List parent component,
 * navigation: Navigation object from react-key-navigation,
 * horizontalAutoScroll: scroll to show item in screen if not totally in,
 * viewport: Html scrollable element reference,
 * vGap: vertical gap added to the vertical scroll if verticalAutoscroll,
 * verticalAutoScroll: scroll to show item in screen if not totally in,
 * itemIndex: Requireable<number>,
 * parentCategory: Category parent component,
 * hGap: gap added to the vertical scroll if verticalAutoscroll,
 * setThisItemActive: function in parent component to set the active element index
 */
ListItem.propTypes = {
  redirectTo: PropTypes.func,
  goBack: PropTypes.func,
  navigation: PropTypes.instanceOf( Navigation ),
  parentList: PropTypes.object,
  parentCategory: PropTypes.instanceOf( [
    Catalog,
    Search
  ] ),
  item: PropTypes.object,
  itemIndex: PropTypes.number,
  viewport: PropTypes.object,
  horizontalAutoScroll: PropTypes.bool,
  hGap: PropTypes.number,
  verticalAutoScroll: PropTypes.bool,
  vGap: PropTypes.number,
  lazyLoadingShift: PropTypes.number,
  setThisItemActive: PropTypes.func,
  type: PropTypes.string
};

ListItem.defaultProps = {
  horizontalAutoScroll: false,
  hGap: 0,
  verticalAutoScroll: false,
  vGap: 0,
  lazyLoadingShift: 5,
  type: ListItem.TYPE_DEFAULT
};