import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CssClassUtils from '../utils/CssClassUtils';
import StringUtils from '../utils/StringUtils';

import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-back.svg';
import { ReactComponent as Book } from '../assets/icons/audiobook.svg';
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUp } from '../assets/icons/chevron-up.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Concert } from '../assets/icons/concert.svg';
import { ReactComponent as Deer } from '../assets/icons/hunt-deer.svg';
import { ReactComponent as Exit } from '../assets/icons/logout.svg';
import { ReactComponent as Fish } from '../assets/icons/fishing-fish.svg';
import { ReactComponent as Home } from '../assets/icons/home.svg';
import { ReactComponent as InLive } from '../assets/icons/live.svg';
import { ReactComponent as Purchase } from '../assets/icons/purchase.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as ShopBasket } from '../assets/icons/purchase.svg';

import { ReactComponent as Next } from '../assets/icons/next.svg';
import { ReactComponent as Previous } from '../assets/icons/previous.svg';
import { ReactComponent as Pause } from '../assets/icons/pause.svg';
import { ReactComponent as Play } from '../assets/icons/play2.svg';
import { ReactComponent as VolumeOn } from '../assets/icons/volume-on.svg';
import { ReactComponent as VolumeOff } from '../assets/icons/volume-muted.svg';
import { ReactComponent as Loading } from '../assets/icons/loading.svg';


/**
 * Icon component
 */
class Icon extends Component {
  /**
   * The base css class name.
   * @type {string}
   **/
  static CSS_CLASS_BASE = 'icon';

  /**
   * The up chevron name
   * @type {string}
   */
  static NAME_CHEVRON_UP = 'chevronUp';

  /**
   * The right chevron name
   * @type {string}
   */
  static NAME_CHEVRON_RIGHT = 'chevronRight';

  /**
   * The down chevron name
   * @type {string}
   */
  static NAME_CHEVRON_DOWN = 'chevronDown';

  /**
   * The let chevron name
   * @type {string}
   */
  static NAME_CHEVRON_LEFT = 'chevronLeft';

  /**
   * The left arrow name
   * @type {string}
   */
  static NAME_ARROW_LEFT = 'arrowLeft';

  /**
   * The home name
   * @type {string}
   */
  static NAME_HOME = 'home';

  /**
   * The hunting name
   * @type {string}
   */
  static NAME_HUNTING = 'hunting';

  /**
   * The fishing name
   * @type {string}
   */
  static NAME_FISHING = 'fishing';

  /**
   * The search name
   * @type {string}
   */
  static NAME_SEARCH = 'search';

  /**
   * The logout name
   * @type {string}
   */
  static NAME_LOGOUT = 'logout';

  /**
   * The exit name
   * @type {string}
   */
  static NAME_EXIT = 'quit';

  /**
   * The purchase name
   * @type {string}
   */
  static NAME_PURCHASE = 'purchase';
  
  /**
   * The concert name
   * @type {string}
   */
  static NAME_CONCERT = 'concert';

  /**
   * The book name
   * @type {string}
   */
  static NAME_BOOK = 'book';

  /**
   * The shopping basket name
   */
  static NAME_SHOP_BASKET = 'shopping-basket';
  
  /**
   * The in live name
   */
  static NAME_LIVE = 'live';

  /**
   * The next name
   */
  static NAME_NEXT = 'next';
  
  /**
   * The previous name
   */
  static NAME_PREVIOUS = 'previous';
  
  /**
   * The pause name
   */
  static NAME_PAUSE = 'pause';
  
  /**
   * The play name
   */
  static NAME_PLAY = 'play';
  
  /**
   * The volume on name
   */
  static NAME_VOLUME_ON = 'volume-on';
  
  /**
   * The volume off name
   */
  static NAME_VOLUME_OFF = 'volume-off';
  
  /**
   * The loading name
   */
  static NAME_LOADING = 'loading';

  /**
   * The secondary color
   * @type {string}
   */
  static COLOR_SECONDARY = 'secondary';
  
  /**
   * The important color
   * @type {string}
   */
  static COLOR_BRAND_PRIM = 'brand-primary';

  /**
   *  The separator name
   * @type {string}
   */
  static SEPARATOR = 'separator';

  /**
   *  The selected name
   * @type {string}
   */
  static SELECTED = 'selected';

  /**
   *  The selected name
   * @type {string}
   */
  static HIDE = 'hide';

  /**
   *  The clickable name
   * @type {string}
   */
  static CLICKABLE = 'clickable';

  /**
   * The secondary color class name
   * @type {string}
   */
  static CSS_CLASS_COLOR_SECONDARY = Icon.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Icon.COLOR_SECONDARY;
  
  /**
   * The important color class name
   * @type {string}
   */
  static CSS_CLASS_COLOR_IMPORTANT = Icon.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Icon.COLOR_BRAND_PRIM;

  /**
   * The separator css classname
   * @type {string}
   */
  static CSS_CLASS_SEPARATOR = Icon.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Icon.SEPARATOR;

  /**
   * The selected css classname
   * @type {string}
   */
  static CSS_CLASS_SELECTED = Icon.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Icon.SELECTED;

  /**
   * The visible css classname
   * @type {string}
   */
  static CSS_CLASS_HIDE = Icon.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Icon.HIDE;

  /**
   * The clickable css classname
   * @type {string}
   */
  static CSS_CLASS_CLICKABLE = Icon.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Icon.CLICKABLE;


  /**
   * Constructor of the Icon class
   * @param props
   */
  constructor( props ) {
    super( props );

    this.getClasses = this.getClasses.bind( this );
  }

  /**
   * Returns classes for the icon HTML element depending on args
   * @returns {string}
   */
  getClasses( color: string, separator: boolean, selected: boolean, hide: boolean, onClick: any ) {
    let classes = (this.props.className) ?
      this.props.className + CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_BASE :
      Icon.CSS_CLASS_BASE;

    switch ( color ) {
      case Icon.COLOR_SECONDARY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_COLOR_SECONDARY;
        break;
      case Icon.COLOR_BRAND_PRIM:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_COLOR_IMPORTANT;
        break;

      default:
        break;
    }

    if ( separator ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_SEPARATOR;
    }
    if ( selected ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_SELECTED;
    }
    if ( hide ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_HIDE;
    }
    if( typeof onClick === 'function' ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Icon.CSS_CLASS_CLICKABLE;
    }
    return classes;
  }

  /**
   * Returns the Svg component depending on the name given
   * @param name
   */
   getSvg( name: string ) {
    switch ( name ) {
      case Icon.NAME_CHEVRON_UP:
        return ChevronUp;
      case Icon.NAME_CHEVRON_RIGHT:
        return ChevronRight;
      case Icon.NAME_CHEVRON_DOWN:
        return ChevronDown;
      case Icon.NAME_CHEVRON_LEFT:
        return ChevronLeft;
      case Icon.NAME_ARROW_LEFT:
        return ArrowLeft;
      case Icon.NAME_HOME:
        return Home;
      case Icon.NAME_HUNTING:
        return Deer;
      case Icon.NAME_FISHING:
        return Fish;
      case Icon.NAME_SEARCH:
        return Search;
      case Icon.NAME_LOGOUT:
        return Exit;
      case Icon.NAME_EXIT:
        return Close;        
      case Icon.NAME_PURCHASE:
        return Purchase;
      case Icon.NAME_CONCERT:
        return Concert;
      case Icon.NAME_BOOK:
        return Book;        
      case Icon.NAME_SHOP_BASKET:
        return ShopBasket;
      case Icon.NAME_LIVE:
        return InLive;
        
      case Icon.NAME_NEXT:
        return Next;
      case Icon.NAME_PREVIOUS:
        return Previous;
      case Icon.NAME_PAUSE:
        return Pause;
      case Icon.NAME_PLAY:
        return Play;
      case Icon.NAME_VOLUME_ON:
        return VolumeOn;
      case Icon.NAME_VOLUME_OFF:
        return VolumeOff;
      case Icon.NAME_LOADING:
        return Loading;
    
      default:
        return Home;
    }
  }

  /**
   * Render method of the Icon class
   * @returns {JSX.Element}
   */
  render() {
    const IconSvg = this.getSvg( this.props.name );
    const { color, separator, selected, hide, onClick } = this.props;

    return (
      <span onClick={ onClick } className={ StringUtils.isEmpty( this.props.className ) ? "icon--wrapper" : this.props.className + "--wrapper" }>
        {
          <IconSvg
            className={ this.getClasses( color, separator, selected, hide, onClick ) }
          />
        }

      </span>
    );
  }

}

export default Icon;


/**
 *
 * @type {{
 *    name: string,
 *    color: string,
 *    className: string
 *    separator: boolean
 *    visible: boolean,
 *    onClick: function
 * }}
 */
Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.oneOf( [
    Icon.COLOR_BRAND_PRIM,
    Icon.COLOR_SECONDARY,
  ] ),
  className: PropTypes.string,
  separator: PropTypes.bool,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};

/**
 *
 * @type {{visible: boolean, className: string, separator: boolean, onClick: ?function}}
 */
Icon.defaultProps = {
  className: '',
  separator: false,
  visible: true,
  onClick: null,
};

