import React, { Component } from "react";
import { goTo, PATH_ROOT } from "../utils/NavigationUtils";

class Logout extends Component {
  constructor( props ) {
    super( props )
    this.disconnect();
  }

  disconnect(){
    localStorage.clear();
    sessionStorage.clear();
    this.props.app.setState( { isLogged: false } );
    // this.props.history.push( '/' );TODO
    goTo( this.props.history, PATH_ROOT );
  }

  render(){
    return(<></>)
  }
}

export default Logout;