import React, { Component } from 'react';
import { Focusable } from 'react-key-navigation';
import Icon from './Icon';
import Text from "./Text";


class MenuItem extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      active: false,
      selected: false,
    }

    this._onEnterDown = this._onEnterDown.bind( this );
    this.timer = null;

    this.item = React.createRef();
  }

  _onEnterDown( obj, navig ) {
    this.props.goTo();
  }

  render() {
    return (
      <li>
        <Focusable
          className={ 'menuitem' +
          (this.state.active ? ' menuitem--active' : '') +
          (this.props.selected ? ' menuitem--selected' : '') +
          (this.props.separator ? ' menuitem--separator' : '') }
          ref={ ( el ) => this.item = el }
          onMouseEnter={ () => this.props.navigation.forceFocus( this.item.focusableId ) }
          onFocus={ () => this.setState( { active: true } ) }
          onBlur={ () => this.setState( { active: false } ) }
          onClick={ ( e, n ) => this._onEnterDown( e, n ) }
          onEnterDown={ this._onEnterDown }
          onSelect={ () => this.setState( { selected: true } ) }
        >
          <Icon name={ this.props.icon }/>
          <Text color={ Text.COLOR_GREY }>{ this.props.children }</Text>
        </Focusable>
      </li>
    );
  }
}

export default MenuItem;