import React, { Component } from 'react';
import Image from '../components/Image.js'
import ReactDOM from 'react-dom';

/**
* This components is the splashscreen of the app. The logo opacity effect on the launch.
*

**/
class SplashScreen extends Component
{
	/**
	* Initiate the loaded state to be sure to lauch the plash screen effect only at the app launch.
	*
	
	*/
	constructor(props)
	{
		super(props);

		this.state = {
			loaded: false 
		}
	}

	/**
	* Change the state of loaded to true after one seconde.
	*
	* @todo Instead of a timeout, check that the current page is fully loaded.
	*
	
	*/
	componentDidMount() 
	{
		const display = setTimeout(() => {
    		this.setState({ loaded: true });
	    }, 1000);

	    const hide = setTimeout(() => {
    		this.props.parent.setState({withSplasher: false});
	    }, 2000);
	}

	/**
	* Render the splash screen.
	*
	* @todo create a wrapper component for the splash-screen classes.
	*
	
	*/
	render()
	{
		return(
			<React.Fragment key="component-splashscreen">
				{ this.props.parent.state.withSplasher && 
				<div className={"splash-screen" + (this.state.loaded ? " action-opacity" : "")}>
					<Image
						url={ require('../assets/img/bg-inlivestream.jpg') }
						alt={ 'Inlivestream' }
						size={ Image.SIZE_FULLSCREEN }
					/>
				</div>
			}
			</React.Fragment>
		);
	}
}

export default SplashScreen;
	