import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../components/Loader';
import CssClassUtils from '../utils/CssClassUtils';
import StringUtils from '../utils/StringUtils';


/**
 * This components is an image.
 *
 **/
class Image extends Component {
  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'image';

  /**
   * The full screen size.
   **/
  static SIZE_FULLSCREEN = 'fullscreen';

  /**
   * The big css class name.
   **/
  static CSS_CLASS_SIZE_FULLSCREEN = Image.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Image.SIZE_FULLSCREEN;

  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false,
      onError: false
    };

    this.onLoad = this.onLoad.bind( this );
  }

  /**
   * Return the css classes (depending on the params).
   *
   * @param string size The size of the image (fullscreen).
   *
   * @return string The css classes.
   *
   
   **/
  getCssClasses( classProp: string, size: string ) {
    let classes = Image.CSS_CLASS_BASE;

    if ( StringUtils.isString( classProp ) && !StringUtils.isEmpty( classProp ) ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + classProp;
    }

    if ( size === Image.SIZE_FULLSCREEN ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Image.CSS_CLASS_SIZE_FULLSCREEN;
    }

    return classes;
  }

  onLoad() {
    this.setState( { isLoaded: true } );
  }

  /**
   * Render the image.
   */
  render() {
    const { className, size, url, alt, onError, withLoader, replacementText } = this.props;
    const classes = this.getCssClasses( className, size );

    return (
      <React.Fragment key="component-image">
        { !this.state.isLoaded && withLoader &&
          <span className={ classes }>
            <Loader/>
          </span>
        }
        {
          // !this.state.onError &&
          <img
            className={ classes }
            src={ url }
            alt={ alt }
            onLoad={ this.onLoad }
            onError={ e => {
              this.setState( { onError: true } );
              if ( typeof onError === 'function' ) {
                onError( e );
              }
            } }
          />
        }
        {
          this.state.onError && !StringUtils.isString( replacementText ) &&
          <span className={ classes }>{ replacementText }</span>
        }
      </React.Fragment>
    );
  }
}

export default Image;

/**
 * @props string url The url of the image.
 * @props string alt The alt of the image.
 * @props string size The size of the button. Giant, big or medium, medium by default.
 **/
Image.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf( [ Image.SIZE_FULLSCREEN ] ),
  withLoader: PropTypes.bool,
  replacementText: PropTypes.string,
  onError: PropTypes.func
};

Image.defaultProps = {
  className: '',
  withLoader: true,
  replacementText: ''
};

	