import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Focusable } from 'react-key-navigation';

import StringUtils from '../utils/StringUtils';
import CssClassUtils from '../utils/CssClassUtils';
import { goBack, goTo } from "../utils/NavigationUtils";
import Text from "./Text";


/**
 * This components is a pill.
 * @TODO action on onClick props
 **/
class Pill extends Component {
  /**
   * The default value.
   **/
  static VALUE_DEFAULT = 'Pill';

 
  /**
   * Initiate the ref and the states.
   * @param Object props
   */
  constructor( props ) {
    super( props );

    this.state = {
      active: false,
    }
    this.button = React.createRef();
  }

  isFocusable() {
      return typeof this.props.onClick === 'function';
  }

  /**
   * Set the button active and force the focus on this button.
   *
   
   */
  onMouseEnter() {
    this.setState( { active: true } );
    this.props.navigation.forceFocus( this.button.current.focusableId );
  }

  /**
   * Return the css classes depending on the params.
   **/
  getCssClasses( isFocusable: boolean ) {
    let classes = Pill.CSS_CLASS_BASE;

    if ( isFocusable ) {
      classes += Pill.CSS_CLASS_BASE + CssClassUtils.CSS_CLASS_SEPARATOR + Pill.CSS_CLASS_FOCUSABLE;
    }

    return classes;
  }

  /**
   * Format the value of the button.
   *
   * @param object value The value inside of the title (images or text).
   *
   * @return object The value of the button.
   */
  getValueFormated( value: Object ) {
    return (StringUtils.isString( this.props.children )) ? StringUtils.toCapitalize( this.props.children ) : this.props.children;
  }

  /**
   * Render the button.
   *
   * @return Render object The button.
   *
   
   */
  render() {
    return (
      this.isFocusable() ? 
      <Focusable
        ref={ this.button }
        className={ this.getCssClasses( this.isFocusable() ) }
        onMouseEnter={ () => this.onMouseEnter() }
        onFocus={ () => this.setState( { active: true } ) }
        onBlur={ () => this.setState( { active: false } ) }
        // onEnterDown={ () => this.action( this.props.url, this.props.replaceUrl ) }
        // onClick={ () => this.action( this.props.url ) }
        navDefault={ this.props.focusOnLoad }
      >
        { this.getValueFormated( this.props.children ) }
      </Focusable> :
      <Text 
        className="pill" 
        color={ Text.COLOR_SECONDARY }
        size={ Text.SIZE_SMALL }
        display={ Text.DISPLAY_INLINE }
      >
        { this.getValueFormated( this.props.children ) }
      </Text>
    )
  }
}

/**
 * @props string url The url of the button. Empty for a goBack.
 * @props bool focusOnLoad To know if this button need to be focused on load, by default false.
 * @props Object navigation The navigation object to permit the forceFocus().
 * @props Object history The history object to permit the push().
 * @props string style The style of the button. Plain or transparent, transparent by default.
 * @props string size The size of the button. Giant, big or medium, medium by default.
 * @props string block The block type of the button. Default or full, full meaning full width.
 **/
// Pill.propTypes = {
//   url: PropTypes.string,
//   replaceUrl: PropTypes.bool,
//   focusOnLoad: PropTypes.bool,
//   navigation: PropTypes.object,
//   history: PropTypes.object,
// };

// Pill.defaultProps = {
//   replaceUrl: false,
//   focusOnLoad: false
// };

export default Pill;