import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Focusable } from 'react-key-navigation';

import StringUtils from '../utils/StringUtils';
import CssClassUtils from '../utils/CssClassUtils';
import { goBack, goTo } from "../utils/NavigationUtils";


/**
 * This components is a button.
 **/
class Button extends Component {
  /**
   * The default value.
   **/
  static VALUE_DEFAULT = 'cliquez ici';

  /**
   * The medium size.
   **/
  static SIZE_MEDIUM = 'size-medium';

  /**
   * The big size.
   **/
  static SIZE_BIG = 'size-big';

  /**
   * The giant size.
   **/
  static SIZE_GIANT = 'size-giant';

  /**
   * The block type default, the default width.
   */
  static BLOCK_DEFAULT = 'block-default';

  /**
   * The block type full, meaning full width button.
   */
  static BLOCK_FULL = 'block-full';

  /**
   * The block type half, meaning half width button.
   */
  static BLOCK_HALF = 'block-half';

  /**
   * The plain style.
   */
  static STYLE_PLAIN = 'style-plain';
  
  /**
   * The secondary style.
   */
  static STYLE_SECONDARY = 'style-secondary';

  /**
   * The transparent style.
   */
  static STYLE_TRANSPARENT = 'style-transparent';
  
  /**
   * The outline style.
   */
  static STYLE_OUTLINE = 'style-outline';
  
  /**
   * The outline style.
   */
  static STYLE_OUTLINE_ROUND = 'style-outline-round';

  /**
   * The focused state.
   */
  static STATE_FOCUS = 'focus';
  
  /**
   * The disabled state.
   */
  static STATE_DISABLED = 'disabled';

  /**
   * The base css class name.
   **/
  static CSS_CLASS_BASE = 'button';

  /**
   * The focus css class name.
   **/
  static CSS_CLASS_STATE_FOCUS = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.STATE_FOCUS;

  /**
   * The disabled css class name.
   **/
  static CSS_CLASS_STATE_DISABLED = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.STATE_DISABLED;

  /**
   * The big css class name.
   **/
  static CSS_CLASS_SIZE_BIG = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.SIZE_BIG;

  /**
   * The giant css class name.
   **/
  static CSS_CLASS_SIZE_GIANT = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.SIZE_GIANT;

  /**
   * The medium css class name.
   **/
  static CSS_CLASS_SIZE_MEDIUM = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.SIZE_MEDIUM;

  /**
   * The full width block class name.
   */
  static CSS_CLASS_BLOCK_DEFAULT = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.BLOCK_DEFAULT;

  /**
   * The full width block class name.
   */
  static CSS_CLASS_BLOCK_FULL = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.BLOCK_FULL;

  /**
   * The full width block class name.
   */
  static CSS_CLASS_BLOCK_HALF = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.BLOCK_HALF;

  /**
   * The transparent style class name.
   */
  static CSS_CLASS_STYLE_TRANSPARENT = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.STYLE_TRANSPARENT;

  /**
   * The transparent style class name.
   */
  static CSS_CLASS_STYLE_SECONDARY = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.STYLE_SECONDARY;

  /**
   * The outline style class name.
   */
   static CSS_CLASS_STYLE_OUTLINE = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.STYLE_OUTLINE;
   
  /**
   * The outline round style class name.
   */
   static CSS_CLASS_STYLE_OUTLINE_ROUND = Button.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Button.STYLE_OUTLINE_ROUND;


  /**
   * Initiate the ref and the states.
   *
   * @param Object props
   */
  constructor( props ) {
    super( props );

    this.state = {
      active: false
    }

    this.button = React.createRef();
  }

  /**
   * Redirect the user to the url provided in the url props.
   * @todo review this function because it can take function or url. Not good.
   * @param url
   * @param replaceUrl
   */
  action( url: any, replaceUrl: boolean ) {
    if ( this.props.isDisabled ) {
      return;
    }

    if ( typeof url === 'function' ) {
      this.props.url();
    } else {
      if ( StringUtils.isEmpty( url ) ) {
        goBack( this.props.history );
      } else {
        goTo( this.props.history, url, replaceUrl );
      }
    }
  }

  /**
   * Set the button active and force the focus on this button.
   */
  onMouseEnter() {
    this.setState( { active: true } );
    this.props.navigation.forceFocus( this.button.current.focusableId );
  }

  /**
   * Return the css classes depending on the params).
   *
   * @param string style The style of the button (plain or transparent).
   * @param string size The size of the button (big or medium).
   * @param string block The type of bluck, default or full (full width).
   *
   * @return string The css classes.
   **/
  getCssClasses( classname: string, style: string, size: string, block: string, isDisabled: Boolean ) {
    let classes = StringUtils.isEmpty( classname ) ? '' : classname + CssClassUtils.CSS_CLASS_SEPARATOR;
    
    classes += Button.CSS_CLASS_BASE;

    if ( size === Button.SIZE_BIG ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_SIZE_BIG;
    } else if ( size === Button.SIZE_GIANT ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_SIZE_GIANT;
    } else {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_SIZE_MEDIUM;
    }

    if ( block === Button.BLOCK_FULL ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_BLOCK_FULL;
    } else if ( block === Button.BLOCK_HALF ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_BLOCK_HALF;
    } else {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_BLOCK_DEFAULT;
    }

    switch( style ) {
      case Button.STYLE_TRANSPARENT:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_STYLE_TRANSPARENT;
        break;
      case Button.STYLE_SECONDARY:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_STYLE_SECONDARY;
        break;
      case Button.STYLE_OUTLINE:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_STYLE_OUTLINE;
        break;
      case Button.STYLE_OUTLINE_ROUND:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_STYLE_OUTLINE_ROUND;
        break;
    }

    if ( this.state.active ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_STATE_FOCUS;
    }
    
    if ( isDisabled ) {
      classes += CssClassUtils.CSS_CLASS_SEPARATOR + Button.CSS_CLASS_STATE_DISABLED;
    }


    return classes;
  }

  /**
   * Format the value of the button.
   *
   * @param object value The value inside of the title (images or text).
   *
   * @return object The value of the button.
   */
  getValueFormated( value: Object ) {
    return (StringUtils.isString( this.props.children )) ? StringUtils.toCapitalize( this.props.children ) : this.props.children;
  }

  /**
   * Render the button.
   *
   * @return Render object The button.
   */
  render() {
    const { children, className, style, size, block, focusOnLoad, url, replaceUrl, isDisabled } = this.props;

    return (
      <Focusable
        ref={ this.button }
        className={ this.getCssClasses( className, style, size, block, isDisabled ) }
        onMouseEnter={ () => this.onMouseEnter() }
        onFocus={ () => this.setState( { active: true } ) }
        onBlur={ () => this.setState( { active: false } ) }
        onEnterDown={ () => this.action( url, replaceUrl ) }
        onClick={ () => this.action( url ) }
        navDefault={ focusOnLoad }
      >
        { this.getValueFormated( children ) }
      </Focusable>
    )
  }
}

/**
 * @props string url The url of the button. Empty for a goBack.
 * @props bool focusOnLoad To know if this button need to be focused on load, by default false.
 * @props Object navigation The navigation object to permit the forceFocus().
 * @props Object history The history object to permit the push().
 * @props string style The style of the button. Plain or transparent, transparent by default.
 * @props string size The size of the button. Giant, big or medium, medium by default.
 * @props string block The block type of the button. Default or full, full meaning full width.
 **/
Button.propTypes = {
  className: PropTypes.string,
  url: PropTypes.any,
  replaceUrl: PropTypes.bool,
  focusOnLoad: PropTypes.bool,
  isDisabled: PropTypes.bool,
  navigation: PropTypes.object,
  history: PropTypes.object,
  style: PropTypes.oneOf( [ Button.STYLE_PLAIN, Button.STYLE_TRANSPARENT, Button.STYLE_OUTLINE, Button.STYLE_SECONDARY ] ),
  size: PropTypes.oneOf( [ Button.SIZE_GIANT, Button.SIZE_BIG, Button.SIZE_MEDIUM ] ),
  block: PropTypes.oneOf( [ Button.BLOCK_DEFAULT, Button.BLOCK_FULL, Button.BLOCK_HALF ] ),
};

Button.defaultProps = {
  className: '',
  isDisabled: false,
  replaceUrl: false,
  focusOnLoad: false,
  value: Button.VALUE_DEFAULT,
  style: Button.STYLE_PLAIN,
  block: Button.BLOCK_DEFAULT,
};

export default Button;