import React, { Component } from 'react';
import { VerticalList } from 'react-key-navigation';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Navigation, { HorizontalList } from "react-key-navigation";

import Button from '../components/Button';
import Image from '../components/Image';
import Title from '../components/Title';
import Icon from '../components/Icon';
import Wrapper from '../components/Wrapper';
import Text from '../components/Text';
import Popup from '../components/Popup';
import Pill from '../components/Pill';

import { getImageUrl, typeFullscreen, visual2 } from '../api/ImageManager';
import { getMovie } from '../api/ApiManager';
import { goBack, isReturnEvent, PATH_HOW_WATCH, PATH_PLAY, PATH_READ } from "../utils/NavigationUtils";
import StringUtils from "../utils/StringUtils";

import ConcertLogo from '../assets/img/Logo_Matt_P.png';
import LivreLogo from '../assets/img/Livre_off.png';
import UserConnection from '../api/UserConnection';


class Details extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false,
      movieLoaded: false,
      film: [],
      popupOpened: false
    };

    this.keyDownListener = this.keyDownListener.bind( this );
    this.openPopup = this.openPopup.bind( this );

    if ( this.props.match.params.FilmID === '' ) {
      goBack( this.props.history );
    }
    this.getMovieData();
  }

  /**
   * Opdn sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) ) {
      goBack( this.props.history );
    }
  }

  openPopup( open: bool = true ) {
    this.setState( { popupOpened: open } );
  }

  getMovieData() {
    axios.get( getMovie( this.props.match.params.FilmID, localStorage.getItem( UserConnection.ITEM_NO_CLIENT ) ) ).then( res => {
      if ( res.data ) {
        // res.data[0].categories = res.data[0].categories.substring(1, res.data[0].categories.length-1);
        // res.data[0].categories = res.data[0].categories.split('||');
        this.setState( {
          film: res.data[0],
          movieLoaded: true,
          logo: this.defineLogo( res.data[0].type_product )
        } );
    console.log('film', res.data[0]);//TODO del 

        localStorage.setItem( "FilmId", this.props.match.params.FilmID );
        localStorage.setItem( "Film", this.state.film.vimeo_hls );
        localStorage.setItem( "FilmVideo", this.state.film.vimeo_id );
        localStorage.setItem( "FilmHls", this.state.film.vimeo_hls );
      } else {
        goBack( this.props.history );
      }
    } );
  }

  defineLogo( type ) {
    switch( type ) {
      case 'Concert':
        return ConcertLogo;
      case 'Livre audio':
        return LivreLogo;
    }
  }

  // Load the movie.
  componentDidMount() {
    this.setState( { isLoaded: true } ); 
    localStorage.setItem( "FilmId", this.props.match.params.FilmID );
    localStorage.setItem( "Film", this.state.film.vimeo_hls );
    localStorage.setItem( "FilmVideo", this.state.film.vimeo_VideoId );
    localStorage.setItem( "FilmHls", this.state.film.vimeo_hls );
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
    this.book = null;
  }

  /**
   * Render method of the Detail component
   * @returns {JSX.Element}
   */
  render() {
    const myUri = getImageUrl( this.props.match.params.FilmID, typeFullscreen, visual2 );

    return (
      <Navigation ref={ ( el ) => {this.navigation = el} } className="detail">
        
          <VerticalList ref={ ( ref ) => this.verticalList = ref }>
            <Image
              url={ myUri }
              alt={ this.state.film.titre }
              size={ Image.SIZE_FULLSCREEN }
              onError={ ( evt ) => { evt.target.src = myUri.replace( 'VISUEL2', 'VISUEL' ) } }
              withLoader={ false }
            />
            {
              !this.state.popupOpened && /* this.state.movieLoaded && */
              <Wrapper overlay={ Wrapper.OVERLAY_GRADIENT_BOTTOM }>
                <Button navigation={ this.navigation } history={ this.props.history }>
                  <Icon name="arrowLeft" color={ Icon.COLOR_SECONDARY } />
                </Button>
                
                  <Wrapper sticky={ Wrapper.STICKY_BOTTOM_MB }>
                    {
                      this.state.movieLoaded ? 
                      this.state.film.categories.map( (category) => {
                        return <Pill>{ category }</Pill>
                      } ) : null
                    }
                    <Title level={ Title.LEVEL_1 } color={ Title.COLOR_SECONDARY } className="detail__title">
                      <Image 
                        url={ this.state.logo } 
                        alt={ this.state.film.title } 
                        onError={ () => console.log('err load title image')}
                        withLoader={ false }
                        replacementText={ this.state.film.title }
                      />
                    </Title>

                    <Wrapper size={ Wrapper.SIZE_TWO_THIRD } padding={ Wrapper.PADDING_NONE }>
                      <HorizontalList>
                        {
                          // !this.state.film.is_purchased &&
                          <Button
                            navigation={ this.navigation }
                            size={ Button.SIZE_BIG }
                            focusOnLoad={ true }
                            history={ this.props.history }
                            url={ 
                              this.state.film.is_purchased ? 
                                ( this.state.film.type_product === 'Livre audio' ? PATH_READ : PATH_PLAY ) + '/' + this.state.film.transaction :
                                this.openPopup
                            }
                            isDisabled={ this.state.film.type_product !== 'Livre audio' /* TODO: */}
                          >
                            {
                              this.state.film.is_purchased ?
                                <Text color={ Text.COLOR_SECONDARY } weight={ Text.WEIGHT_BOLD }>
                                  { 
                                    this.state.film.type_product === 'Livre audio' ? 
                                      'Lire' : 
                                      'Lecture' + this.state.film.title_available ? this.state.film.title_available : ''
                                  }
                                </Text> :
                                <Text color={ Text.COLOR_SECONDARY } weight={ Text.WEIGHT_BOLD }>comment acheter ?</Text>
                            }
                          </Button>
                        }
                        {/* TODO: icon apercu | Bande annonce/ apercu action*/}
                        {
                          <Button
                            navigation={ this.navigation }
                            size={ Button.SIZE_BIG }
                            history={ this.props.history }
                            url={ 
                              this.state.film.type_product === 'Livre audio' ? 
                                PATH_READ + '/' + this.state.film.transaction + '/preview' : 
                                // PATH_READ + '/' + this.props.film.preview 
                                PATH_PLAY + '/' + this.state.film.transaction
                            }
                          > {/* TODO */}
                            <Text color={ Text.COLOR_SECONDARY } weight={ Text.WEIGHT_BOLD }>
                              { this.state.film.type_product === 'Livre audio' ? 'aperçu' : 'bande annonce' }
                              {/* TODO: BA live/apercu book */}
                            </Text>
                          </Button>
                        }
                      </HorizontalList>
                      {
                        StringUtils.isString( this.state.film.title_description ) &&
                        <Text color={ Text.COLOR_SECONDARY } /* size={ Text.SIZE_LARGE } */>
                          <span>{ this.state.film.title_description }</span>
                        </Text>
                      }
                      <Text color={ Text.COLOR_SECONDARY } size={ Text.SIZE_SMALL } capitalize={ false }>
                        {/* TODO return from php data depending on server date */}
                        {
                          Date.now() < this.state.film.date_available && this.state.film.description_before
                        } {
                          Date.now() > this.state.film.date_expiry && this.state.film.description_replay
                        } {
                          Date.now() >= this.state.film.date_available && Date.now() <= this.state.film.date_expiry && this.state.film.description_live
                        }
                      </Text>
                    </Wrapper>
                  </Wrapper>
              </Wrapper>
              } 
              {
                this.state.popupOpened &&
                <Popup 
                  overlay={ true } 
                  closeFunction={ () => this.openPopup( false ) } 
                  navigation={ this.navigation }
                  history={ this.props.history }
                >
                  <Text color={ Text.COLOR_SECONDARY }>
                    L'achat de produit est uniquement disponible sur la version web de Inlivestream. 
                    Veuillez vous rendre sur le site https://inlive.aitakes.com/ pour effectuer vos 
                    achats. Ils seront ensuite disponible depuis n'importe quel périphérique.
                  </Text>
                </Popup>
              }
          </VerticalList>
      </Navigation>
    )
  }
}

export default Details;