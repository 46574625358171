import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import axios from 'axios';
import 'react-multi-carousel/lib/styles.css';
import Loader from '../components/Loader';

import { getImageUrl, typeCarouselFullscreen } from '../api/ImageManager';
import { apiLandingCarouselImage } from '../api/ApiManager';


/**
 * This carousel is used on the landing page only.
 **/
class CarouselFullScreen extends Component {
  /**
   * The responsive configuration for the carousel sizes.
   */
  static responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  /**
   * Initiate the ref and the states.
   * @param props
   */
  constructor( props ) {
    super( props );

    this.state = {
      images: [],
      isLoaded: false,
    }

    this.getImages();
  }

  /**
   * Get the images to be put in the carousel.
   */
  getImages() {
    axios
      .get( apiLandingCarouselImage() )
      .then( res => {
        this.setState( {
          images: res.data,
          isLoaded: true
        } );
      } );
  }

  /**
   * Render the carousel.
   * @return Render object The carousel.
   */
  render() {
    return (
      <div>
        { this.state.isLoaded &&
        <Carousel
          responsive={ CarouselFullScreen.responsive }
          showDots={ true }
          dotListClass="react-multi-carousel-dots-list"
        >
          { this.state.images.map( ( imageName ) =>
            <img
              key={ imageName }
              className="react-multi-carousel-image"
              alt={ imageName }
              src={ getImageUrl( imageName, typeCarouselFullscreen ) }
            />
          ) }
        </Carousel>
        }
        { !this.state.isLoaded &&
        <Loader/>
        }
      </div>
    )
  }
}

export default CarouselFullScreen;