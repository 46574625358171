import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import List from './List';
import Catalog from '../pages/Catalog';
import Arrow from "./Arrow";


/**
 * Category contains the category title and a list of items
 */
class Category extends Component {
  /**
   * Default name for the category if none given
   * @type {string}
   */
  static NAME_DEFAULT = 'sans nom';

  /**
   * Display arrow when element focus is greater than the value
   * @type {number}
   */
  static LEFT_ARROW_MIN_INDEX = 0;

  /**
   * Number of list to display before and after the active one
   */
  static SHIFT_DISPLAY_LIST = 4;

  /**
   * Item index focus
   */
  actualIndex;

  /**
   * Constructor for the Category class
   * @param props
   */
  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false,
      mouseFocus: false
    };

    this.actualIndex = 0;
    this.category = React.createRef();
    this.list = React.createRef();
  }

  /**
   * Focus next/previous element in the list depending on the direction arg
   * @param direction
   */
  focusNewElement( direction ) {
    const newIndex = this.actualIndex + ( direction ? 1 : ( - 1 ) );
    if ( newIndex >= 0 && newIndex < this.props.items.length ) {
      this.props.navigation.forceFocus( this.list.itemsRef[ newIndex ].itemFocusable.focusableId );
    }
    this.setMouseFocus( true );
  }

  /**
   * Change actual item index focus
   * @param index
   */
  setActualIndex( index: number ) {
    this.actualIndex = index;
  }

  /**
   * Set mouseFocus state to given state
   * @param active
   */
  setMouseFocus( active: boolean ) {
    this.setState( { mouseFocus: active } );
  }

  /**
   * Returns if true the component is in the screen or close to be
   * @returns {boolean}
   */
  isCategoryVisible(): boolean {
    const { activeList } = this.props.parent.state;

    return (this.props.categoryIndex > activeList - Category.SHIFT_DISPLAY_LIST && this.props.categoryIndex < activeList + Category.SHIFT_DISPLAY_LIST )
  }

  /**
   * Set state loaded to true after component mount
   */
  componentDidMount() {
    this.setState( { isLoaded: true } );
  }

  /**
   * Prevent rendering component if not in screen
   * @param nextProps
   * @param nextState
   * @param nextContext
   * @returns {boolean}
   */
  shouldComponentUpdate( nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any ): boolean {
    return this.isCategoryVisible();
  }

  /**
   * Render method for the Category class
   * @returns {JSX.Element}
   */
  render() {
    return (
      this.state.isLoaded &&
      <div
        ref={ ( el ) => this.category = el }
        className="category"
      >
        <Title level={ Title.LEVEL_2 } color={ Title.COLOR_SECONDARY }>{ this.props.name }</Title>
          <div className="category__arrow__container">
            {/* {
              this.props.parent.state.activeList === this.props.categoryIndex &&
              this.state.mouseFocus &&
              this.actualIndex > Category.LEFT_ARROW_MIN_INDEX &&
              <Arrow
                direction={ Arrow.DIRECTION_LEFT }
                background={ Arrow.BCKGND_GRADIENT }
                onClick={ () => this.focusNewElement( false ) }
              />
            } */}
            {/* {
              this.props.parent.state.activeList === this.props.categoryIndex &&
              this.state.mouseFocus &&
              this.actualIndex < this.props.items.length - 1 &&
              <Arrow
                direction={ Arrow.DIRECTION_RIGHT }
                background={ Arrow.BCKGND_GRADIENT }
                onClick={ () => this.focusNewElement( true ) }
              />
            } */}
            <List
              ref={ ( el ) => this.list = el }
              items={ this.props.items }
              category={ this.category }
              categoryIndex={ this.props.categoryIndex }
              setThisListActive={ () => this.props.setListActive( this.props.categoryIndex ) }
              parentCategory={ this }
              redirectTo={ this.props.redirectTo }
              goBack={ this.props.goBack }
              navigation={ this.props.navigation }
              viewport={ this.props.viewport }
              wrappedList
            />
          </div>
      </div>
    );
  }
}


export default Category;


/**
 * @type {{
 *   parent: Requireable<Categories>,
 *   goBack: Requireable<(...args: any[]) => any>,
 *   navigation: Requireable<object>,
 *   viewport: Requireable<object>,
 *   name: Requireable<string>,
 *   redirectTo: Requireable<(...args: any[]) => any>, items: Requireable<any[]>,
 *   key: Requireable<number>
 * }}
 */
Category.propTypes = {
  key: PropTypes.number,
  name: PropTypes.string,
  items: PropTypes.array,
  parent: PropTypes.instanceOf( Catalog ),
  redirectTo: PropTypes.func,
  goBack: PropTypes.func,
  navigation: PropTypes.object,
  viewport: PropTypes.object
};

/**
 *
 * @type {{name: string}}
 */
Category.defaultProps = {
  name: Category.NAME_DEFAULT,
};