import React, { Component, Fragment } from 'react';
import Menu from './Menu';
import CssClassUtils from '../utils/CssClassUtils';
import Text from "./Text";


/**
 * Sidebar component
 */
class Sidebar extends Component {
  /**
   * The base CSS class for the sidebar
   * @type {string}
   */
  static CSS_CLASS_BASE = 'sidebar';

  /**
   * Default category when not specified, default menu item selected
   */
  static DEFAULT_CATEGORY = 'home';

  /**
   * The not active state
   */
  static STATE_HIDE = 'hide';

  /**
   * The css class for the active state
   */
  static CSS_CLASSS_STATE_HIDE = CssClassUtils.CSS_CLASS_SEPARATOR + Sidebar.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Sidebar.STATE_HIDE;

  /**
   * The css class for the focus state
   */
  static CSS_CLASSS_STATE_FOCUS = CssClassUtils.CSS_CLASS_SEPARATOR + Sidebar.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + CssClassUtils.CSS_CLASS_STATE_FOCUS;

  /**
   * Constructor of the Sidebar classe
   * @param props
   */
  constructor( props ) {
    super( props );

    this.state = {
      active: false,
      focus: false,
    }

    this.onSidebarClick = this.onSidebarClick.bind( this );
  }

  /**
   * Change the active state of the object depending on the status arg
   * @param status
   */
  setActive( status ) {
    this.setState( { active: status } );
  }

  /**
   * Return classes for the Sidebar component
   * @param active
   * @param focus
   */
  getClasses( active: boolean, focus: boolean ) {
    let classes = Sidebar.CSS_CLASS_BASE;

    classes += !active ? Sidebar.CSS_CLASSS_STATE_HIDE : '';

    classes += focus ? Sidebar.CSS_CLASSS_STATE_FOCUS : '';

    return classes;
  }

  /**
   * Focus the first element of the sidebar and change the active state
   */
  onSidebarClick() {
    if ( !this.state.active ) {
      this.props.navigation.forceFocus( this.menu.menuVList.focusableId )
    } else {
      this.setActive( false );
    }
  }

  /**
   * Render method of the Sidebar class
   * @returns {JSX.Element}
   */
  render() {
    const { active, focus } = this.state;
    return (
      <Fragment>
        <div
          className={ this.getClasses( active, focus ) }
          onClick={ this.onSidebarClick }
          onMouseEnter={ () => this.setState( { focus: true } ) }
          onMouseOut={ () => this.setState( { focus: false } ) }
        >
          {/*  /!*TODO profile section of the sidebar (VX design), ajouter focusable*!/*/ }
          {/*<div className="sidebar__profile">*/ }

          {/*  <div className="sidebar__profile__icon">*/ }
          {/*    <img src={ require( '../assets/img/user-icon-default.png' ) } alt/>*/ }
          {/*  </div>*/ }

          {/*  <div className="sidebar__profile__username">*/ }
          {/*    <Title level={ Title.LEVEL_3 }>Username</Title>*/ }
          {/*  </div>*/ }
          {/*</div>*/ }

          <Menu
            ref={ ( el ) => this.menu = el }
            category={ this.props.category || Sidebar.DEFAULT_CATEGORY }
            navigation={ this.props.navigation }
            page={ this.props.parent }
            history={ this.props.history }
            visible={ this.state.active }
            openSidebar={ () => this.setActive( true ) }
            closeSidebar={ () => this.setActive( false ) }
          />

          <div className="sidebar__logo">
            {
              this.state.active &&
              <img src={ require( '../assets/img/logo-white.png' ) } alt="Inlivestream"/>
            }{
              !this.state.active &&
              <img src={ require( '../assets/img/playstore.png' ) } alt="Inlivestream"/>
            }
          </div>


        </div>
        <div
          className={ 'sidebar__overlay' + (!this.state.active ? ' sidebar__overlay--hide' : '') }
          onClick={ () => this.setActive( false ) }
        />
      </Fragment>
    );
  }
}


export default Sidebar;
