import React, { Component, Fragment } from 'react';

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import { Carousel } from '@cybergab/gab-react-responsive-carousel';
import '@cybergab/gab-react-responsive-carousel/lib/styles/carousel.min.css';
import { getImageUrl, typeCarousel, visual3 } from '../api/ImageManager';
import Text from "./Text";
import Loader from "./Loader";

const largeur = '100%';
const hauteur = '35vh';

class GBCarousel extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      lastEventType: '',
      myRef: null,
      titleVisible: true,
      imageStatus: false,
    }

    this.carousel = React.createRef();
  }

  /**
   * @TODO
   */
  handleImageLoaded( aff_titre) {
    if ( aff_titre !== '1' ) {
      this.setState( { titleVisible: false } );
    }
    this.setState( { imageStatus: true } );
  }

  /**
   * @TODO
   */
  handleImageErrored() {
    this.setState( { imageStatus: false } );
  }

  _renderMap( myImg, i ) {
    return (
      <div
        key={ myImg.transaction }
        style={ { width: largeur, height: (hauteur) } }
      >
        <img
          alt=''
          style={ { width: largeur, height: (hauteur), alignSelf: 'center' } }
          src={ getImageUrl( myImg.transaction, typeCarousel, visual3 ) }
          onLoad={ this.handleImageLoaded.bind( this, myImg.aff_titre ) }
          onError={ this.handleImageErrored.bind( this ) }
        />
        {
          this.state.titleVisible && <Text shadow={ true } size={ Text.SIZE_LARGE }>{ myImg.titre }</Text>
        }
        {
          !this.state.imageStatus && <Loader color="#FFFFFF" size={ 32 }/>
        }
      </div>
    )
  }

  render() {
    return (
      <div
        style={ { width: largeur, height: hauteur } }
      >
        {
          this.props.parent.state.carouselLoaded &&
          <Carousel
            { ...this.props }
            ref={ ( el ) => this.carousel = el }
            onClickItem={ ( e, obj ) => {this.props.redirectTo( '/detail/' + obj.key );} }
          >
            { this.props.images.map( ( img, i ) => this._renderMap( img, i ) ) }
          </Carousel>
        }
        {
          !this.props.parent.state.carouselLoaded &&
          <div
            style={ {
              width: "100%",
              height: "100%",
              textAlign: 'center',
              justifyContent: 'center',
              marginTop: '10vh'
            } }
          >
            <Spinner color="#fff" size={ 52 } speed={ 0.7 } animating={ true }/>
          </div>
        }
      </div>

    )
  }
}

export default GBCarousel