import React, { Component } from 'react';
import { getApiAdminUrl } from "./ApiManager";


/**
 * Image for thumbnails
 * @type {number}
 */
export const typeThumb = 1;

/**
 * Fullscreen image
 * @type {number}
 */
export const typeFullscreen = 2;

/**
 * Image for Carousel component
 * @type {number}
 */
export const typeCarousel = 3;

/**
 * Image for CarouselFullscreen component
 * @type {number}
 */
export const typeCarouselFullscreen = 4;

/**
 * First visual illustration of the movie
 * @type {string}
 */
export const visual1 = 'VISUEL';

/**
 * Second visual illustration of the movie
 * @type {string}
 */
export const visual2 = 'VISUEL2';

/**
 * Third visual illustration of the movie
 * @type {string}
 */
export const visual3 = 'VISUEL3';

/**
 * Height for the thumbnail image
 */
const HEIGHT_THUMB = '200';

/**
 * Fullscreen height for the image
 */
const HEIGHT_FULL = window.innerHeight;

/**
 * Height for the carousel image
 */
const HEIGHT_CAROUSEL = '720';

/**
 * Fullscreen width for the image
 */
const WIDTH_FULL = window.innerWidth;

/**
 * Argument crop to fit
 */
const CROP_TO_FIT = '&crop-to-fit';

/**
 * Returns the URL to retrieve an image
 * @param filmId
 * @param type
 * @param visual
 * @returns {string}
 * @constructor
 */
export function getImageUrl( filmId, type, visual = visual1 ) {
  
  let url = getApiAdminUrl() + 'cimage/webroot/img.php?src=../../../DATA/TMP/ils-';
  url += type === typeCarouselFullscreen ? 'img-tv/' : filmId + '-' + visual + '.jpg';

  switch ( type ) {
    case typeThumb:
      url += '&h=' + HEIGHT_THUMB + CROP_TO_FIT;
      break;
    case typeFullscreen:
      url += '&w=' + WIDTH_FULL + '&h=' + HEIGHT_FULL + CROP_TO_FIT;
      break;
    case typeCarousel:
      url += '&h=' + HEIGHT_CAROUSEL + CROP_TO_FIT;
      break;
    case typeCarouselFullscreen:
      url += filmId;
      break;
    // Thumb
    default:
      url += '&h=' + HEIGHT_THUMB + CROP_TO_FIT;
  }

  return url;
}