import React, { Component } from 'react';
import Navigation, { HorizontalList } from 'react-key-navigation';
import PropTypes from 'prop-types';

import ListItem from './ListItem.js';
import Catalog from '../pages/Catalog';
import Search from '../pages/Search';


/**
 * Horizontal list, wrappable, containing ListItems
 */
class List extends Component {
  /**
   * Array containing references of the items
   * @type {[]}
   */
  itemsRef = [];

  /**
   * Gap value in px for the vertical scroll on list focus
   * @type {number}
   */
  static SCROLL_GAP = 40;

  /**
   * Constructor of the List class
   * @param props
   */
  constructor( props ) {
    super( props );
    this.leftArrowDisabled = false;
    this.rightArrowDisabled = false;
    this.hzScrollActive = true;

    this.state = {
      isLoaded: false,
      activeItem: null,
    }

    this.horizontalList = React.createRef();
    this.onFocusList = this.onFocusList.bind( this );
  }

  /**
   * Set activeItem state to given index or null
   * @param index
   */
  setItemActive( index: number = null ) {
    this.setState( { activeItem: index } );
  }

  /**
   * Scroll to see the list in screen if not totally visible on focus event,
   * set active state to true
   */
  onFocusList() {
    const { viewport, category } = this.props;
    const categoryTop = category.getBoundingClientRect().top;
    const categoryBottom = category.getBoundingClientRect().bottom;
    const { top, bottom } = viewport.getBoundingClientRect();

    if ( categoryBottom > bottom ) {
      viewport.scrollTop += categoryBottom - bottom + List.SCROLL_GAP;
    } else if ( categoryTop < top ) {
      viewport.scrollTop += categoryTop - top /*- List.SCROLL_GAP*/;
    }

    this.props.setThisListActive();
  }

  /**
   * Set state isLoaded to true
   */
  componentDidMount() {
    this.setState( { isLoaded: true } );
  }

  /**
   * Render method of the List class
   * @returns {JSX.Element}
   */
  render() {
    return (
      this.state.isLoaded &&
      <HorizontalList
        ref={ ( el ) => this.horizontalList = el }
        className={ 'list' + (this.props.wrapped ? ' list--wrapped' : '') }
        onFocus={ this.onFocusList }
        retainLastFocus
      >
        {
          this.props.items.map( ( item, i ) =>
            <ListItem
              ref={ ( el ) => this.itemsRef[ i ] = el }
              redirectTo={ this.props.redirectTo }
              goBack={ this.props.goBack }
              navigation={ this.props.navigation }
              parentList={ this }
              parentCategory={ this.props.parentCategory }
              categoryIndex={ this.props.categoryIndex }
              setThisItemActive={ () => this.setItemActive( i ) }
              itemIndex={ i }
              viewport={ this.props.viewport }
              item={ item }
              horizontalAutoScroll
              hGap={ 20 }
              withText={ true }
          />
          )
        }
        <div className="list__gap"/>
      </HorizontalList>
    );
  }
}


export default List;


/**
 * Properties of the List component
 * @type {{goBack: Requireable<(...args: any[]) => any>, navigation: Requireable<unknown>, catIsInViewport: Requireable<(...args: any[]) => any>, redirectTo: Requireable<(...args: any[]) => any>, parentCategory: Requireable<unknown>, items: Requireable<any[]>, wrappedList: Requireable<boolean>}}
 */
List.propTypes = {
  redirectTo: PropTypes.func,
  goBack: PropTypes.func,
  navigation: PropTypes.instanceOf( Navigation ),
  parentCategory: PropTypes.instanceOf( [
    Catalog,
    Search
  ] ),
  catIsInViewport: PropTypes.func,
  items: PropTypes.array,
  wrappedList: PropTypes.bool
};

/**
 * Default properties fot the List component
 * @type {{wrappedList: boolean}}
 */
List.defaultProps = {
  wrappedList: false
};