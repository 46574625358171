import React, { Component } from 'react';

import 'video-react/styles/scss/video-react.scss';
import axios from 'axios';
import {
  Player,
  Shortcut,
  ControlBar,
  VolumeMenuButton,
  LoadingSpinner,
  BigPlayButton,
  FullscreenToggle
} from 'video-react';
import HLSSource from './HLSSource';
import Navigation from 'react-key-navigation';
import { getImageUrl, typeFullscreen, visual2 } from '../api/ImageManager';
import { getDeviceId } from '../utils/DeviceUtils';
import Icon from "./Icon";
import App from "../App";
import { goBack, isReturnEvent } from "../utils/NavigationUtils";


class MyPlayer extends Component {
  /**
   * Interval between update progress video request in s
   * @type {number}
   */
  static UPDATE_PROGRESS_VIDEO_INTERVAL = 10;

  /**
   * Film id
   */
  filmId;

  /**
   * User's data
   */
  userData;

  /**
   * Duration of the movie
   */
  duration;

  /**
   * Current position in the movie
   */
  position;

  static ALTERNATIVE_KEYS = [ 'vewd', 'zeasn', 'lg', 'localhost' ];

  constructor( props ) {
    super( props );

    this.state = {
      isLoaded: false,
      quitIconVisible: false,
      film: '',
      synopsis: '',
      keyCode: '',
      playerWidth: window.innerWidth,
      playerHeight: window.innerHeight
    };

    this.userData = this.getUserData();
    this.filmId = this.props.match.params.FilmID;
    this.newShortcuts = this.defineShortcuts();
    this.keyDownListener = this.keyDownListener.bind( this );
  }

  defineShortcuts() {
    const shortcuts = [
      //__________FORWARD___________
      {
        keyCode: 39, // Right arrow
        handle: ( player, actions ) => {
          actions.forward( 10, {
            action: 'forward-10',
            source: 'shortcut'
          } ); // Go forward 5 seconds
        }
      },
      {
        keyCode: 38, // Commande Hisense
        handle: ( player, actions ) => {
          actions.forward( 5, {
            action: 'forward-5',
            source: 'shortcut'
          } ); // Go forward 10 seconds
        }
      },
      {
        keyCode: 228, // l ????
        handle: ( player, actions ) => {
          actions.forward( 10, {
            action: 'forward-10',
            source: 'shortcut'
          } ); // Go forward 10 seconds
        }
      },
      //__________BACKWARD___________
      {
        keyCode: 37, // Left arrow
        handle: ( player, actions ) => {
          actions.replay( 10, {
            action: 'replay-10',
            source: 'shortcut'
          } ); // Go backward 5 seconds
        }
      },
      {
        keyCode: 40, // Down arrow
        handle: ( player, actions ) => {
          actions.replay( 5, {
            action: 'replay-5',
            source: 'shortcut'
          } ); // Go backward 5 seconds
        }
      },
      {
        keyCode: 227, // l ???
        handle: ( player, actions ) => {
          actions.replay( 10, {
            action: 'replay-10',
            source: 'shortcut'
          } ); // Go backward 10 seconds
        }
      },
      //__________PLAY/PAUSE___________
      {
        keyCode: 179,
        handle: ( player, actions ) => {
          if ( player.paused ) {
            actions.play( {
              action: 'play',
              source: 'shortcut'
            } );
          } else {
            actions.pause( {
              action: 'pause',
              source: 'shortcut'
            } );
          }
        }
      },
      {
        keyCode: 13,
        handle: ( player, actions ) => {
          if ( player.paused ) {
            actions.play( {
              action: 'play',
              source: 'shortcut'
            } );
          } else {
            actions.pause( {
              action: 'pause',
              source: 'shortcut'
            } );
          }
        }
      },
      //__________STOP___________
      {
        keyCode: 178,
        handle: ( player, actions ) => {
          actions.pause( {
            action: 'pause',
            source: 'shortcut'
          } );
          actions.seek( 0, {
            action: 'seek',
            source: 'shortcut'
          } )
        }
      }
    ];

    return MyPlayer.ALTERNATIVE_KEYS.includes( this.props.hostname ) ?
      shortcuts.concat( [
        {
          keyCode: 415, // VK_PLAY
          handle: ( player, actions ) => {
            actions.play( {
              action: 'play',
              source: 'shortcut'
            } );
          }
        },
        {
          keyCode: 19, // VK_PAUSE
          handle: ( player, actions ) => {
            actions.pause( {
              action: 'pause',
              source: 'shortcut'
            } );
          }
        },
        {
          keyCode: 417, // VK_FAST_FWD
          handle: ( player, actions ) => {
            actions.forward( 10, {
              action: 'forward-10',
              source: 'shortcut'
            } );
          }
        },
        {
          keyCode: 412, // VK_REWIND
          handle: ( player, actions ) => {
            actions.replay( 10, {
              action: 'replay-10',
              source: 'shortcut'
            } );
          }
        },
        {
          keyCode: 413, // VK_STOP
          handle: ( player, actions ) => {
            actions.pause( {
              action: 'pause',
              source: 'shortcut'
            } );
            actions.seek( 0, {
              action: 'seek',
              source: 'shortcut'
            } )
          }
        }
      ] ) : shortcuts
  }

  //TODO Make global class for user data
  getUserData() {
    return JSON.parse( localStorage.getItem( 'LOGIN_TOKEN' ) );
  }

  updateProgressVideo( cnt = 0 ) {
    const { video } = this.player.video;

    video.ontimeupdate = () => {
      if ( !this.duration ) {
        this.duration = video.duration;
      }

      if ( this.duration && !this.position || this.position + MyPlayer.UPDATE_PROGRESS_VIDEO_INTERVAL < video.currentTime ) {
        this.position = video.currentTime;

        //TODO apimanager
        axios.get(
          'https://vod-dev.zone300.com/api/appli.vx/update_ProgressMovies.php?NoCli=' + this.userData.NoCli +
          '&video=' + this.filmId +
          '&time=' + this.position +
          '&timeTotal=' + this.duration +
          '&device=' + getDeviceId()
        ).then( function ( res ) {
          if ( !res.data.ret ) {
            if ( res.data.data === 'authentication' ) {
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = '/';
            }
          }
        } ).catch( err => {
          // console.log(err)
        } );
      }
    }
  }

  /**
   * Detect change in player DOM Element classes, and display or not the return icon
   */
  addQuitButton() {
    const player = this.player.manager.rootElement;

    const observer = new MutationObserver( ( event ) => {
      if ( event[ 0 ].target.classList.contains( 'video-react-user-active' ) ) {
        this.setState( { quitIconVisible: true } );
      } else {
        this.setState( { quitIconVisible: false } );
      }
    } );

    observer.observe( player, {
      attributes: true,
      attributeFilter: [ 'class' ],
      childList: false,
      characterData: false
    } );
  }


  /**
   * Opdn sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) ) {
      goBack( this.props.history );
    }
  }

  componentDidMount() {
    switch ( App.DEVICE ) {
      case 'desktop':
        this.addQuitButton();
        break;
    }

    this.setState( {
      film: localStorage.getItem( 'Film' ),
      poster: getImageUrl( this.filmId, typeFullscreen, visual2 ),
      isLoaded: true
    } );

    // this.mainPlayer.focus(); // DEBUG KEY CODE
    // this.player.handleFocus(); // VERSION FIRESTICK / TV OnLine Prod[

    localStorage.removeItem( "Film" );
    localStorage.removeItem( "FilmVideo" );
    localStorage.removeItem( "FilmHls" );
    sessionStorage.setItem( 'lastLocation', this.props.location.pathname );
    // this.updateProgressVideo();


    window.addEventListener( 'keydown', this.keyDownListener );
  }

  componentWillUnmount() {
    if ( this.player ) {
      this.player.handleBlur();
    }
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  render() {
    return (
      <Navigation ref={ ( el ) => { this.navigation = el } }>
        {
          App.DEVICE === 'desktop' && this.state.isLoaded &&
          <div className="player__button-back">
            <Icon
              name="arrowLeft"
              hide={ !this.state.quitIconVisible }
              onClick={ (() => {
                // this.props.history.goBack();
                goBack( this.props.history );
              }) }
            />
          </div>
        }
        <div
          className="player"
          ref={ ( ref ) => this.mainPlayer = ref }
          onKeyDown={ ( key ) => {
            this.setState( { keyCode: key.keyCode } );
          } }
        >
          <Player
            poster={ this.state.poster }
            ref={ ( player ) => this.player = player }
            fluid={ false }
            videoId='Player1'
            preload="auto"
            autoPlay={ true }
            width="100%"
            height="100%"
            onEnded={ () => goBack( this.props.history ) }
            onCanPlay={ () => this.player.manager.rootElement.firstChild.removeAttribute( 'src' ) }
          >
            <LoadingSpinner/>
            <HLSSource
              ref={ ( el ) => this.hlssource = el }
              isVideoChild
              src={ this.state.film }
            />
            <BigPlayButton position="center" id="playBtn"/>
            <ControlBar autoHide={ true }>
              <VolumeMenuButton vertical/>
              <FullscreenToggle disabled={ App.DEVICE !== 'desktop' }/>
            </ControlBar>
            <Shortcut clickable={ false } shortcuts={ this.newShortcuts }/>
          </Player>
        </div>
      </Navigation>
    )
  }
}

export default MyPlayer;