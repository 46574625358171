import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import CssClassUtils from '../utils/CssClassUtils';


/**
 * Arrow component, can be clickable, can contains overlay
 */
class Arrow extends Component {
  /**
   * The base css class name.
   * @type {string}
   **/
  static CSS_CLASS_BASE = 'arrow';

  /**
   * The gradient background
   * @type {string}
   */
  static BCKGND_GRADIENT = 'gradient';

  /**
   * The up direction
   * @type {string}
   */
  static DIRECTION_UP = 'up';

  /**
   * The right direction
   * @type {string}
   */
  static DIRECTION_RIGHT = 'right';

  /**
   * The down direction
   * @type {string}
   */
  static DIRECTION_DOWN = 'down';

  /**
   * The down direction
   * @type {string}
   */
  static DIRECTION_LEFT = 'left';

  /**
   * The gradient background css class name
   * @type {string}
   */
  static CSS_CLASS_BCKGND_GRADIENT = Arrow.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Arrow.BCKGND_GRADIENT;

  /**
   * The up direction css class name
   * @type {string}
   */
  static CSS_CLASS_DIRECTION_UP = Arrow.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Arrow.DIRECTION_UP;

  /**
   * The right direction css class name
   * @type {string}
   */
  static CSS_CLASS_DIRECTION_RIGHT = Arrow.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Arrow.DIRECTION_RIGHT;

  /**
   * The down direction css class name
   * @type {string}
   */
  static CSS_CLASS_DIRECTION_DOWN = Arrow.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Arrow.DIRECTION_DOWN;

  /**
   * The left direction css class name
   * @type {string}
   */
  static CSS_CLASS_DIRECTION_LEFT = Arrow.CSS_CLASS_BASE + CssClassUtils.CSS_CLASSNAME_STATE_SEPARATOR + Arrow.DIRECTION_LEFT;


  /**
   * Constructor of the Arrow class
   * @param props
   */
  constructor( props ) {
    super( props );
  }

  /**
   * Returns the classes for the arrow component
   * @param direction
   * @param background
   * @returns {string}
   */
  getClasses( direction: string, background: string ) {
    let classes = Arrow.CSS_CLASS_BASE;

    switch ( direction ) {
      case Arrow.DIRECTION_UP:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_UP;
        break;
      case Arrow.DIRECTION_RIGHT:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_RIGHT;
        break;
      case Arrow.DIRECTION_DOWN:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_DOWN;
        break;
      case Arrow.DIRECTION_LEFT:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_LEFT;
        break;
      default:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_RIGHT;
    }

    switch ( direction ) {
      case 'gradient':
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_BCKGND_GRADIENT;
        break;
      default:
        classes += CssClassUtils.CSS_CLASS_SEPARATOR + Arrow.CSS_CLASS_BCKGND_GRADIENT;
    }

    return classes;
  }

  /**
   * Return the icon name depending on the arrow direction
   * @param direction
   * @returns {string}
   */
  getIconName( direction: string ) {
    switch ( direction ) {
      case Arrow.DIRECTION_UP:
        return Icon.NAME_CHEVRON_UP;
      case Arrow.DIRECTION_RIGHT:
        return Icon.NAME_CHEVRON_RIGHT;
      case Arrow.DIRECTION_DOWN:
        return Icon.NAME_CHEVRON_DOWN;
      case Arrow.DIRECTION_LEFT:
        return Icon.NAME_CHEVRON_LEFT;
      default:
        return Icon.NAME_CHEVRON_RIGHT;
    }
  }

  /**
   * Render method of the Arrow class
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div
        className={ this.getClasses( this.props.direction ) }
        onClick={ this.props.onClick }
      >
        <Icon name={ this.getIconName( this.props.direction ) } color={ Icon.COLOR_SECONDARY }/>
      </div>
    );
  }
}


export default Arrow;


Arrow.propTypes = {
  direction: PropTypes.oneOf( [
    Arrow.DIRECTION_UP,
    Arrow.DIRECTION_RIGHT,
    Arrow.DIRECTION_DOWN,
    Arrow.DIRECTION_LEFT,
  ]),
  background: PropTypes.oneOf( [
    Arrow.BCKGND_GRADIENT,
  ] ),
  onClick: PropTypes.func,
};