import React, { Component } from 'react';
import axios from 'axios';
import Navigation, { VerticalList, HorizontalList, Grid } from 'react-key-navigation';
import { withLastLocation } from 'react-router-last-location';

import Sidebar from '../components/SideBar';
import Wrapper from '../components/Wrapper';
import Input from '../components/Input';
import ListItem from '../components/ListItem';
import Text from '../components/Text';
import Loader from '../components/Loader';

import { getImageUrl, typeThumb } from '../api/ImageManager';
import { getPurchasesUrl } from '../api/ApiManager';
import { goBack, goTo, isReturnEvent, PATH_PURCHASE, PATH_SEARCH } from "../utils/NavigationUtils";
import App from "../App";
import Axios from 'axios';
import UserConnection from '../api/UserConnection';
import Title from '../components/Title';


/**
 * Component corresponding to the purchases page
 */
class Purchase extends Component {
  /**
   * Numbers of columns for the purchases list display
   * @type {number}
   */
  static GRID_COLUMNS = 4;

  purchases = []

  /**
   * Contains the reference of the result search scrollable div
   */
  scrollableDiv;

  /**
   * Constructor of the Purchase class
   * @param props
   */
  constructor( props ) {
    super( props )

    this.state = {
      isSearchStarted: false,
      inputActive: false,
      mouseFocus: false,
      actualIndex: null,
      isLoaded: false,
      search: '',
      activeItem: null,
      purchasesLoaded: false
    };

    this.getPurchases();
    this.navigation = React.createRef();
    this.scrollableDiv = React.createRef();

    this.keyDownListener = this.keyDownListener.bind( this );
  }


  /**
   * Change actual item index focus
   * @param index
   */
  setActualIndex( index: number ) {
    this.setState( { actualIndex: index } );
  }

  /**
   * Change state inputActive value
   * @param active
   */
  setInputActive( active: boolean ) {
    this.setState( { inputActive: active } );
  }

  /**
   * Set mouseFocus state to given state
   * @param active
   */
  setMouseFocus( active: boolean ) {
    this.setState( { mouseFocus: active } );
  }

  /**
   * Set activeItem state to given index or null
   * @param index
   */
  setItemActive( index: number = null ) {
    this.setState( { activeItem: index } );
  }

  getPurchases() {
    Axios.get( getPurchasesUrl( localStorage.getItem( UserConnection.ITEM_NO_CLIENT ) ) )
      .then( res => {
        this.purchases = res.data.data;
        this.setState( { purchasesLoaded: true } )
        this.navigation.forceFocus( this.grid.children[0].children[0].focusableId );
        console.log(this.grid.children[0].children[0].focusableId);
      } )
  }

  /**
   * Open sidebar on specified item focus
   * @param evnt
   */
  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) && this.sidebar && this.navigation && !this.sidebar.state.active ) {
      this.sidebar.setActive( true );
      this.navigation.forceFocus( this.sidebar.menu.quit.item.focusableId );
      goTo( this.props.history, PATH_PURCHASE, true );
    }
  }

  /**
   * Restore last search from session if comeback from detail page & set state loaded to true
   */
  componentDidMount() {
    this.setState( { isLoaded: true } );
    window.addEventListener( 'keydown', this.keyDownListener );
  }

  shouldComponentUpdate( nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any ): boolean {
    if ( this.props.match.params.action === 'quit' && !nextProps.match.params.action ){
      return false;
    }
    return true;
  }

  componentDidUpdate( prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS ) {
    if ( this.props.match.params.action === 'quit' ) {
      this.keyDownListener( new KeyboardEvent( 'keydown', { keyCode: 27 } ) );
    }
  }

  /**
   * Remove eventlistener
   */
  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
  }

  /**
   * Render method of the Purchase class
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Navigation ref={ ( el ) => { this.navigation = el } }>
        <div
          ref={ ( el ) => this.searchPage = el }
          className="purchase"
        >
          {
            this.state.isLoaded && this.state.purchasesLoaded &&
            <HorizontalList>
              <Sidebar
                ref={ ( el ) => this.sidebar = el }
                category="purchase"
                history={ this.props.history }
                navigation={ this.navigation }
                parent={ this }
              />
              <VerticalList>
                <div className="purchase__content" ref={ this.scrollableDiv }>
                  <Title color={ Title.COLOR_SECONDARY } level={ Title.LEVEL_1 }>mes achats</Title>
                  {
                    // this.state.purchasesLoaded &&
                    <Grid
                      ref={ ( el ) => this.grid = el }
                      columns={ Purchase.GRID_COLUMNS }
                      rows={ Math.ceil( this.purchases.length / Purchase.GRID_COLUMNS ) }
                    >
                      {
                        this.purchases.map(
                          ( item, i ) => {
                            return (
                              <ListItem
                                redirectTo={ ( url ) => goTo( this.props.history, url ) }
                                goBack={ () => goBack( this.props.history ) }
                                navigation={ this.navigation }
                                parentList={ this }
                                parentCategory={ this }
                                categoryIndex={ 0 }
                                itemIndex={ i }
                                setThisItemActive={ () => this.setItemActive( i ) }
                                lazyLoadingShift={ 20 }
                                viewport={ this.scrollableDiv.current }
                                item={ item }
                                verticalAutoScroll
                                vGap={ 40 }
                                withText={ true }
                                type={ ListItem.TYPE_GRID }
                              />
                            )
                          }
                        )
                      }
                    </Grid>
                  }
                  {
                    !this.state.isLoaded && <Loader/>
                  }
                </div>
              </VerticalList>
            </HorizontalList>
          }
        </div>
      </Navigation>
    );
  }
}

export default Purchase;