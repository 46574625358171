import React, { Component } from "react";
import HTMLFlipBook from "react-pageflip";
import Navigation, { HorizontalList } from 'react-key-navigation';
import { goBack, isReturnEvent } from "../utils/NavigationUtils";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Text from "../components/Text";
import StringUtils from "../utils/StringUtils";


class Reader extends Component {
  static PAGE_WIDTH = window.innerWidth * 0.9 / 2;
  // static OUTER_SHADOW_FULL = 0;
  static OUTER_SHADOW_L = 'left';
  static OUTER_SHADOW_R = 'right';
  static FLIP_DELAY_COVERS = 1500;
  // static FLIP_DELAY_PAGES = 10000;
  
  static HAS_AUDIO = true;
  static BOOK_FLIP_TIME = 700;
  static PAGE_RATIO = 1.114585;
  // static PAGES_NB = 8;
  static BOOK_HAS_FIRST_COVER = true;

  audio = null;

  constructor( props ) {
    super( props );
    this.state = {
      isLoaded: false,
      volumeActive: true,
      currentPageIndex: null,
      playStarted: false,
      audioPlaying: false,
      pageHasAusio: false,
    };

    this.audio = new Audio();
    this.initAudioEventListeners();
    this.isPreview = this.isPreviewMode();

    this.navigation = React.createRef();
    this.book = React.createRef();
    this.progressbar = React.createRef();
    this.progressMarker = React.createRef();

    this.keyDownListener = this.keyDownListener.bind( this );


    //TODO: database
    this.pageNb = this.isPreview ? 4 : 8;

  }

  isPreviewMode() {
    return this.props.match.params.mode === 'preview'
  }

  keyDownListener( evnt ) {
    if ( isReturnEvent( evnt ) ) {
      goBack( this.props.history );
    }

    // switch ( evnt.keyCode ) {
    //   case 39: //RIGHT ARROW
    //   console.log('right')
    //     this.book.pageFlip().flipNext();
    //     break;
    //   case 37: //RIGHT ARROW
    //     this.book.pageFlip().flipPrev();
    //     break;
    // }
  }

  /**
   * TODO: hasAudio | dynamic audio page binding
   * @param {Event} event 
   */
  onPageFlip( event ) {
    this.setCurrentIndex( event.data );
    this.updateProgressBar();

    switch( event.data > 0 ? event.data + 1 : event.data ) {
      case 2:
      case 4:
      case 6:
        this.initPageAudio();
        break;

      default:
        // this.audio.pause();
        this.audio.load();
        this.setState( { pageHasAudio: false } );
    }

    if ( this.book && event.data + 1 >= this.book.pageFlip().getPageCount() ){
      if ( this.state.audioPlaying ) {
        console.log('pause2');//TODO:del
        this.audio.pause();      
      }
      this.setState( { playStarted: false } );
    }
  }

  togglePlayPause() {
    //turn to first page if click play on last page
    if (
      !this.state.playStarted &&
      this.book.pageFlip().getCurrentPageIndex() + 1 >=
        this.book.pageFlip().getPageCount()
    ) {
      console.log('flip1');
      this.book.pageFlip().flip(0);
      return;
    }

    if (this.state.playStarted && this.state.audioPlaying ) {
      console.log('pause4');//TODO:del
      this.audio.pause();
    } else {
      if (this.state.pageHasAudio && !this.state.audioPlaying ) {
        console.log('play2');//TODO:del
        this.audio.play();
      } else { 
        console.log('noaudio');//TODO:del
        this.delay().then( () => {
          if( this.state.playStarted ) {
            console.log('flip2 next');
            this.book.pageFlip().flipNext();
          }
        } );
      }
    }

    this.setState( { playStarted: !this.state.playStarted } );
  }

  initPageAudio( url: String ) {
    console.log('initPAgeAudio()');
    url = 'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/audio_10s.mp3';//TODO: del
    this.audio.src = url;
    this.audio.load();
    this.setState( { pageHasAudio: true } );
  }

  initAudioEventListeners() {
    this.audio.addEventListener('canplaythrough', e => {
      if ( this.state.playStarted && !this.state.audioPlaying ) {
        console.log('playAA');
        this.audio.play();
      }
    });

    this.audio.addEventListener('ended', e => {
      console.log('audio ended');//TODO:del
      // console.log('pause5');//TODO:del
      // this.audio.pause();
      console.log('flip3 next');
      this.book.pageFlip().flipNext();
    });

    this.audio.addEventListener('waiting', e => {
      console.log('audio waiting');//TODO:del
      this.setState( { audioLoading: true } );
    } );
    
    this.audio.addEventListener('playing', e => {
      console.log('audio playing');//TODO:del
      this.setState( { audioLoading: false, audioPlaying: true } );

    } );

    this.audio.addEventListener('pause', e => {
      console.log('audio pause');//TODO:del
      this.setState( { audioPlaying: false } );
    } );

    this.audio.addEventListener('error', err => {
      console.log('audio error', err);//TODO: del
      this.setState( { audioPlaying: false } );
    } );
  }

  onBookChangeState( event ) {
    console.log(event);
    if ( [ 'flipping', 'user_fold' ].includes( event.data ) ) {
      this.audio.pause();
    }
    // else if ( event.data === 'read' && this.state.isPlaying ) {
    //   this.audio.play();
    // }
  }

  setCurrentIndex( i ) {
    this.setState( { currentPageIndex: i > 0 ? i + 1 : i } );
  }

  updateProgressBar() {
    if ( this.progressbar && this.progressMarker ){
      let percent = 100 * this.state.currentPageIndex / this.pageNb;
      this.progressbar.style.background = 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) ' + percent + '%, rgba(148,147,146,1) ' + percent + '%, rgba(148,147,146,1) 100%)';
      this.progressMarker.style.left = percent * 0.985 + '%';
    }
  }

  toggleVolume() {
    this.audio.muted = this.state.volumeActive;
    this.setState( { volumeActive: !this.state.volumeActive } );
  }

  getFormatedPageNums() {
    return !this.isPreview ? [
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-001.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-002.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-003.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-004.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-005.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-006.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-007.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-008.jpg',
    ] : [
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-001.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-002.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-003.jpg',
      'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-008.jpg',
    ];
    // ____________________________________
    // const pages = [];
    
    // for( let i = 1; i <= this.pageNb; i++ ) {
    //   // pages.push( i.toString().padStart( 3, '0' ) );
    //   pages.push( StringUtils.formatInteger( i.toString(), 3 ) );
    // }
    // return pages;
  }

  delay( n = 2000 ) {  
    return new Promise(done => {
      setTimeout(() => {
        done();
      }, n);
    });
  }

  componentDidMount() {
    window.addEventListener( 'keydown', this.keyDownListener );
    this.setState( { isLoaded: true } ); 
  }

  componentWillUnmount() {
    window.removeEventListener( 'keydown', this.keyDownListener );
    this.audio.pause();
    this.audio.removeAttribute( 'src' );
    this.audio.load();
  }

  render() {
    return (
      <Navigation ref={ ref => this.navigation = ref }>
        <div className="reader">
          <Button className="reader__button--back" navigation={ this.navigation } history={ this.props.history }>
            <Icon className="reader__bar__icon --left" name="arrowLeft" color={ Icon.COLOR_SECONDARY } />
          </Button>
          <HTMLFlipBook
            ref={ ref => this.book = ref }
            height={ Reader.PAGE_WIDTH / Reader.PAGE_RATIO }
            width={ Reader.PAGE_WIDTH }
            flippingTime={ Reader.BOOK_FLIP_TIME }
            showCover={ true }
            onFlip={ e => this.onPageFlip( e ) }
            onInit={ e => {
              this.setCurrentIndex( e.data.page );
              // this.updateOuterShadow( 0 );
            } }
            onChangeState={ e => this.onBookChangeState( e ) }
            startZIndex={ 10 }
            // useMouseEvents={ false }
            // maxShadowOpacity={/* [0, 1] */}
            // drawShadow={false}
          >
            {
              this.getFormatedPageNums().map( (page, i) => {
                return <div number={ i }><img src={ page } width="100%" /></div>
              } )
              
              // this.getFormatedPageNums().map( (num, i) => {
              //   return <div number={ i }><img src={ 'https://inlive.aitakes.com/api/appli_inlive/audio-book-inlive/Livret_MP_book-page-' + num + '.jpg' } width="100%" /></div>
              // } )

            }
          </HTMLFlipBook>
          {
            // this.state.isLoaded && 
            <HorizontalList className="reader__bar">
              <Button 
                navigation={ this.navigation }
                history={ this.props.history }
                size={ Button.SIZE_MEDIUM }
                style={ Button.STYLE_OUTLINE_ROUND }
                url={ () => {
                  const book = this.book.pageFlip();

                  if ( book.getCurrentPageIndex() <= 0 ) {
                    book.flip( book.getPageCount() - 1 );
                  } else {
                    book.flipPrev();
                  }
                } }
              >
                <Icon className="reader__bar__icon --previous"name={ Icon.NAME_PREVIOUS } color={ Icon.COLOR_SECONDARY }/>
              </Button>
              <Button 
                navigation={ this.navigation }
                history={ this.props.history }
                size={ Button.SIZE_MEDIUM }
                style={ Button.STYLE_OUTLINE_ROUND }
                url={ () => this.togglePlayPause() }
                focusOnLoad={ true }
              >
                {/* {
                  this.state.audioPlaying ? 
                    <Icon name={ Icon.NAME_PAUSE } color={ Icon.COLOR_SECONDARY }/> :
                    <Icon name={ Icon.NAME_PLAY } color={ Icon.COLOR_SECONDARY }/>
                } */}
                {
                  (!this.state.playStarted && <Icon className="reader__bar__icon --play" name={ Icon.NAME_PLAY } color={ Icon.COLOR_SECONDARY }/>) ||
                  (this.state.playStarted && /* !this.state.audioLoading && */ <Icon className="reader__bar__icon --pause" name={ Icon.NAME_PAUSE } color={ Icon.COLOR_SECONDARY }/>) /* || */
                  /* (this.state.playStarted && this.state.audioLoading && <Icon className="reader__bar__icon --loading" name={ Icon.NAME_LOADING } color={ Icon.COLOR_SECONDARY }/>) */
                }
              </Button>
              <Button 
                navigation={ this.navigation }
                history={ this.props.history }
                size={ Button.SIZE_MEDIUM }
                style={ Button.STYLE_OUTLINE_ROUND }
                url={ () => {
                  const book = this.book.pageFlip();
                  if ( book.getCurrentPageIndex() + 1 >= book.getPageCount() ) {
                    book.flip( 0 );
                  } else {
                    book.flipNext();
                  }
                } }
              >
                <Icon className="reader__bar__icon --next" name={ Icon.NAME_NEXT } color={ Icon.COLOR_SECONDARY }/>
              </Button>              
              
              <Text 
                className="reader__progress-text" 
                color={ Text.COLOR_SECONDARY }
                size={ Text.SIZE_SMALL }
              >{ this.state.currentPageIndex + "/" + this.pageNb }</Text>

              <div className="reader__progress-bar">
                <span className="reader__progress-bar__background" ref={ ref => this.progressbar = ref }></span>
                <span className="reader__progress-bar__marker" ref={ ref => this.progressMarker = ref }></span>
              </div>

              <Button 
                navigation={ this.navigation }
                history={ this.props.history }
                size={ Button.SIZE_MEDIUM }
                style={ Button.STYLE_OUTLINE_ROUND }
                url={ () => this.toggleVolume() }
              >
                {
                  this.state.volumeActive ? 
                    <Icon className="reader__bar__icon --volume-on" name={ Icon.NAME_VOLUME_ON } color={ Icon.COLOR_SECONDARY }/> : 
                    <Icon className="reader__bar__icon --volume-off" name={ Icon.NAME_VOLUME_OFF } color={ Icon.COLOR_SECONDARY }/>
                }
              </Button>    
            </HorizontalList>
          }
        </div>
      </Navigation>
    );
  }
}

export default Reader;

//TODO:
// sur dernière page, bouton suivant devient retour au début: flip sur la page d'accueil
//                    bouton progress legeremnt décalé sur la droite 
// box shadow ou filter dropshadow de la taille actuelle du livre 
// couleur de l'épaisseur des pages en dur
// tranche du livre pour le flip 
// chemin de lecture (fin de l'audio => page suivante)
//      Si pas d'audio pas de lecture automatique ou timer ????
